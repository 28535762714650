<template>
  <div @click="open()">
    <slot name="trigger" />
  </div>
  <ZnModal :show="isOpen" size="find-size" @close="close()">
    <template #body>
      <div class="flex flex-col gap-4">
        <h3 class="text-grays-darkest mt-4 text-xl font-semibold">
          Find your Size
        </h3>
        <div class="my-6 self-center">
          <img
            src="/img/find-your-size/lens-width.svg"
            alt="Lens width"
            class="w-full"
          />
        </div>
        <span class="text-sm"
          >Size is based on the <span class="font-semibold">frame width</span>.
          It is measured horizontally across the frame from hinge to
          hinge.</span
        >
        <SizeTable :table-data="AdultSizes" title="Adult" />
        <div class="flex flex-col gap-2">
          <SizeTable :table-data="KidsSizes" title="Kids" />
          <span class="text-sm">*Suggested age</span>
        </div>
      </div>
    </template>
  </ZnModal>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import SizeTable from './SizeTable.vue'

const isOpen = ref(false)
const open = () => {
  isOpen.value = true
}
const AdultSizes = [
  { size: 'XS', range: '110-118 mm' },
  { size: 'S', range: '119-125 mm' },
  { size: 'M', range: '126-132 mm' },
  { size: 'L', range: '133-140 mm' },
  { size: 'XL', range: '141+ mm' },
]

const KidsSizes = [
  { size: 'XS', range: '90-106 mm; Ages 1-3*' },
  { size: 'S', range: '107-112 mm; Ages 4-7*' },
  { size: 'M', range: '113-118 mm; Ages 8-12*' },
  { size: 'L', range: '119-150 mm; Ages 13-18*' },
]

const close = () => {
  isOpen.value = false
}
</script>
