<template>
  <div
    class="w-fit cursor-pointer rounded-full ring-2 ring-offset-2"
    :class="{
      'ring-teal-primary': selected,
      'ring-grays-light': !selected,
    }"
  >
    <div
      class="h-6 w-6 overflow-hidden rounded-full bg-clip-border p-0.5"
      :style="`${
        typeOfColor(
          ZnColorDotMap[color?.toLowerCase() as keyof typeof ZnColorDotMap],
        ) === 'image'
          ? 'background-image'
          : 'background'
      }: ${ZnColorDotMap[color?.toLowerCase() as keyof typeof ZnColorDotMap]}`"
      data-testid="color-dot"
    ></div>
  </div>
</template>
<script setup lang="ts">
import { toRefs } from 'vue'
import { ZnColorDotMap, type ZnColorDotProps } from './types'
const props = withDefaults(defineProps<ZnColorDotProps>(), {
  color: undefined,
  size: 'base',
  selectedColor: 'ring-grays-dark',
})

const { color } = toRefs(props)

const typeOfColor = (color?: string): 'background' | 'image' => {
  return color?.includes('data:image') ? 'image' : 'background'
}
</script>
