<template>
  <span
    class="inline-flex items-center text-neutral-500 before:content-['('] after:content-[')']"
    :class="[
      sizeClasses,
      {
        'rounded-full py-0.5 font-medium ring-1 ring-inset ring-neutral-200 before:content-none after:content-none':
          pill,
      },
    ]"
    data-testid="counter"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: 'base',
  },
  pill: {
    type: Boolean,
    default: false,
  },
})
const { size, pill } = toRefs(props)

const sizeClasses = computed(() => {
  switch (size?.value) {
    case '3xs':
      return ['text-3xs', { 'px-1': pill.value }]
    case '2xs':
      return ['text-2xs', { 'px-1.5': pill.value }]
    case 'xs':
      return ['text-xs', { 'px-2': pill.value }]
    case 'sm':
      return ['text-sm', { 'px-2.5': pill.value }]
    case 'lg':
      return ['text-lg', { 'px-3.5': pill.value }]
    default:
      return ['text-base', { 'px-3': pill.value }]
  }
})
</script>
