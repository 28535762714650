<template>
  <ZenniZnFilterFilterCategory
    v-if="facet"
    title="Prescription Type"
    :count="selectedProxy.length"
  >
    <ul class="flex flex-col">
      <ZenniZnListItem
        v-for="{ value, productCount, label } in computedFacetValues"
        :key="value"
        :aria-label="label"
        size="lg"
        @click="toggleSelected(value)"
      >
        <template #prefix>
          <ZnCheckbox
            :model-value="selectedProxy.includes(value)"
            class="pointer-events-none flex items-center p-1"
            :aria-label="label"
            :aria-name="value"
          />
        </template>
        <div class="flex gap-2">
          <span class="pl-1">{{ label }}</span>
          <ZenniZnCounter v-if="productCount">{{
            productCount
          }}</ZenniZnCounter>
        </div>
      </ZenniZnListItem>
      <li
        v-if="!showAll && facet.values.length > itemsToShow"
        class="mx-auto cursor-pointer font-semibold"
      >
        <a class="text-teal-primary" @click="showAll = true"> Show more + </a>
      </li>
    </ul>
  </ZenniZnFilterFilterCategory>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { maxBy } from 'lodash-es'
import type {
  CheckboxItemProps,
  CheckboxItemEmits,
} from '~/components/Zenni/Plp/filter-types'

const emit = defineEmits<CheckboxItemEmits>()
const props = defineProps<CheckboxItemProps>()

const itemsToShow = 8

const showAll = ref(false)
const facetToShow = computed(() => {
  if (showAll.value) {
    return props.facet
  }
  return {
    ...props.facet,
    values: props.facet?.values.slice(0, itemsToShow),
  }
})

const toggleSelected = (value: string) => {
  const index = selectedProxy.value.indexOf(value)
  if (index === -1) {
    selectedProxy.value.push(value)
  } else {
    selectedProxy.value.splice(index, 1)
  }
  emit('update:selected', selectedProxy.value)
}

const selectedProxy = computed(() => props.selected)

const computedFacetValues = computed(() => {
  const nonPrescriptions = facetToShow.value?.values?.filter(
    (facet) => facet.value === 'nonprescription',
  )
  const withPrescriptions = facetToShow.value?.values?.filter(
    (facet) => facet.value !== 'nonprescription',
  )

  const withProgressives = facetToShow.value?.values?.filter(
    (facet) => facet.value === 'progressive',
  )

  const nonPrescription = {
    label: 'Non-Prescription',
    value: 'nonprescription',
    productCount:
      maxBy(nonPrescriptions, (item: any) => item.productCount)?.productCount ??
      0,
  }

  const prescription = {
    label: 'Prescription',
    value: 'prescription',
    productCount:
      maxBy(withPrescriptions, (item: any) => item.productCount)
        ?.productCount ?? 0,
  }

  const progressive = {
    label: 'Progressives',
    value: 'progressive',
    productCount:
      maxBy(withProgressives, (item: any) => item.productCount)?.productCount ??
      0,
  }

  return [prescription, progressive, nonPrescription].filter(
    (x) => x.productCount > 0,
  )
})
</script>
