<template>
  <HeadlessMenu v-slot="{ open }" as="div" class="relative z-50 inline-block">
    <HeadlessMenuButton>
      <slot name="buttonText" :open="open" />
    </HeadlessMenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <HeadlessMenuItems
        class="absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      >
        <slot name="content" />
      </HeadlessMenuItems>
    </transition>
  </HeadlessMenu>
</template>

<script setup></script>
