<template>
  <section class="mx-auto flex flex-col">
    <div>
      <h2
        class="mx-auto max-w-xs text-center text-2xl font-semibold tracking-wide lg:max-w-none"
      >
        Glasses that go beyond safety
      </h2>
      <div
        class="relative mx-auto mt-10 flex w-full max-w-screen-md justify-center"
      >
        <img width="720" src="/img/landing/safety-highlight.png" />
        <div
          class="absolute -left-20 top-[35%] hidden items-center text-left lg:flex"
        >
          <span class="pb-3 font-semibold">{{ highlightText[0] }}</span>
          <div
            class="relative ml-2 h-px w-32 bg-black after:absolute after:-top-0.5 after:left-full after:h-1.5 after:w-1.5 after:rounded-full after:bg-black"
          ></div>
        </div>
        <div
          class="absolute -left-20 top-[45%] hidden w-1/2 items-center text-left lg:flex"
        >
          <span class="font-semibold">{{ highlightText[1] }}</span>
          <div
            class="relative ml-2 h-px w-44 bg-black after:absolute after:-top-0.5 after:left-full after:h-1.5 after:w-1.5 after:rounded-full after:bg-black"
          ></div>
        </div>
        <div
          class="absolute -left-20 top-[55%] hidden w-1/2 items-center text-left lg:flex"
        >
          <span class="font-semibold">{{ highlightText[3] }}</span>
          <div
            class="relative ml-2 h-px w-44 bg-black after:absolute after:-top-0.5 after:left-full after:h-1.5 after:w-1.5 after:rounded-full after:bg-black"
          ></div>
        </div>
        <div
          class="absolute -right-20 top-[28%] hidden items-center text-left lg:flex"
        >
          <div
            class="relative mr-2 h-px w-36 bg-black after:absolute after:-top-0.5 after:right-full after:h-1.5 after:w-1.5 after:rounded-full after:bg-black"
          ></div>
          <span class="w-32 font-semibold">{{ highlightText[2] }}</span>
        </div>
        <div
          class="absolute -right-20 top-[45%] hidden items-center text-left lg:flex"
        >
          <div
            class="relative mr-2 h-px w-72 bg-black after:absolute after:-top-0.5 after:right-full after:h-1.5 after:w-1.5 after:rounded-full after:bg-black"
          ></div>
          <span class="w-32 font-semibold">{{ highlightText[4] }}</span>
        </div>
        <div
          class="absolute -right-20 top-[58%] hidden items-center text-left lg:flex"
        >
          <div
            class="relative mr-2 h-px w-[22.2rem] bg-black after:absolute after:-top-0.5 after:right-full after:h-1.5 after:w-1.5 after:rounded-full after:bg-black"
          ></div>
          <span class="w-32 font-semibold">{{ highlightText[5] }}</span>
        </div>
      </div>
      <div
        class="mx-auto grid max-w-sm grid-cols-2 gap-x-4 gap-y-2 px-6 pb-10 lg:hidden"
      >
        <div
          v-for="text in highlightText"
          :key="text"
          class="flex items-center gap-2"
        >
          <ZenniIconsIconTick
            size="sm"
            class="text-grays-darkest stroke-grays-darkest"
          />{{ text }}
        </div>
      </div>
    </div>
    <div class="py-10">
      <h3
        class="mx-auto max-w-xs text-center text-xl font-semibold tracking-wide lg:max-w-none"
      >
        Add on's customized to your job
      </h3>
      <div
        class="mt-10 grid grid-cols-1 place-items-start gap-10 px-4 pb-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:pb-2"
      >
        <div
          v-for="(aux, i) in coatings"
          :key="`coatingLensCat-${i}`"
          class="flex w-full flex-col items-center justify-center space-y-4"
        >
          <img :src="aux.image" class="w-[56px]" />
          <span class="font-semibold">{{ aux.name }}</span>
          <p class="text-center text-sm xl:w-52">
            <span class="font-semibold">Best for: </span>{{ aux.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const highlightText = [
  'Impact-resistant',
  'Anti-reflective',
  'Confortable fit',
  'UV protection',
  'Water-resistant',
  'Nose pads',
]
const coatings = [
  {
    image: '/img/icons/coating/anti-fog.svg',
    name: 'Anti-fog',
    description:
      'high humidity, rapid temperature changes, wearing masks and safety glasses at the same time, steam.',
  },
  {
    image: '/img/icons/coating/oil-resistant-alt.svg',
    name: 'Oil-resistant',
    description: 'avoiding smudges from handling greasy tools or foods.',
  },
  {
    image: '/img/icons/lens/transitions-alt.svg',
    name: 'Transitions® Gen S™',
    description:
      'moving between light and dark environments, bright environments.',
  },
  {
    image: '/img/icons/lens/sunglasses.svg',
    name: 'Sunglasses Tints',
    description:
      'reducing brightness and glare, increasing contrast and depth perception.',
  },
]
</script>
