<template>
  <nav
    class="flex items-center justify-between border-t border-neutral-200"
    role="navigation"
    aria-label="pagination"
  >
    <button
      aria-label="Go to previous page"
      :disabled="selectedPage <= 1"
      class="flex gap-3 px-3 sm:px-6"
      :class="{ 'cursor-not-allowed': selectedPage <= 1 }"
      aria-name="prev-page"
      @click="prevPage"
    >
      <ZenniIconsIconChevronRight class="rotate-180" />
      <span class="text-grays-darkest hidden font-semibold sm:inline-flex">
        Previous
      </span>
    </button>
    <ul class="flex justify-center">
      <li v-if="!pages.includes(1) && pages.length > 0">
        <div
          :class="[
            'flex border-t-4 border-transparent pt-1',
            {
              '!border-teal-primary border-t-4 font-medium': selectedPage === 1,
            },
          ]"
        >
          <button
            type="button"
            class="hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900 min-w-[38px] rounded-md p-3 text-neutral-500 sm:px-4 md:w-12"
            :aria-current="selectedPage === 1"
            @click="setPage(1)"
          >
            1
          </button>
        </div>
      </li>
      <li v-if="startPage > 2">
        <div class="flex border-t-4 border-transparent pt-1">
          <button
            type="button"
            disabled
            aria-hidden="true"
            class="rounded-md px-1 py-3 text-neutral-500 md:w-12"
          >
            ...
          </button>
        </div>
      </li>
      <li
        v-if="
          maxVisiblePages === 1 &&
          selectedPage === totalPages &&
          pages.length > 0 &&
          totalItems > 1
        "
      >
        <div class="flex border-t-4 border-transparent pt-1">
          <button
            type="button"
            class="hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900 min-w-[38px] rounded-md p-3 text-neutral-500 sm:px-4 md:w-12"
            :aria-current="endPage - 1 === selectedPage"
            @click="setPage(endPage - 1)"
          >
            {{ endPage - 1 }}
          </button>
        </div>
      </li>
      <li v-for="page in pages" :key="`page-${page}`">
        <div
          :class="[
            'flex border-t-4 border-transparent pt-1',
            {
              '!border-teal-primary border-t-4 font-semibold':
                selectedPage === page,
            },
          ]"
        >
          <button
            type="button"
            :class="[
              'hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900 min-w-[38px] rounded-md p-3 text-neutral-500 sm:px-4 md:w-12',
              {
                'hover:!text-primary-800 active:!text-primary-900 !text-neutral-900 hover:!bg-transparent':
                  selectedPage === page,
              },
            ]"
            :aria-label="`Page ${page} of ${totalPages}`"
            :aria-current="selectedPage === page"
            @click="setPage(page)"
          >
            {{ page }}
          </button>
        </div>
      </li>
      <li v-if="maxVisiblePages === 1 && selectedPage === 1 && totalItems > 1">
        <div class="flex border-t-4 border-transparent pt-1">
          <button
            type="button"
            class="hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900 min-w-[38px] rounded-md p-3 text-neutral-500 sm:px-4 md:w-12"
            :aria-label="`Page 2 of ${totalPages}`"
            @click="setPage(2)"
          >
            2
          </button>
        </div>
      </li>
      <li v-if="endPage < totalPages - 1">
        <div class="flex border-t-4 border-transparent pt-1">
          <button
            type="button"
            disabled
            aria-hidden="true"
            class="rounded-md px-1 py-3 text-neutral-500 md:w-12"
          >
            ...
          </button>
        </div>
      </li>
      <li v-if="!pages.includes(totalPages)">
        <div
          :class="[
            'flex border-t-4 border-transparent pt-1',
            {
              '!border-teal-primary border-t-4 font-semibold':
                selectedPage === totalPages,
            },
          ]"
        >
          <button
            type="button"
            class="hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900 min-w-[38px] rounded-md p-3 text-neutral-500 sm:px-4 md:w-12"
            :class="{
              'hover:!bg-transparent hover:!text-neutral-500':
                selectedPage === totalPages,
            }"
            :aria-current="totalPages === selectedPage"
            :disabled="selectedPage === totalPages"
            @click="setPage(totalPages)"
          >
            {{ totalPages }}
          </button>
        </div>
      </li>
    </ul>
    <button
      aria-label="Go to next page"
      :disabled="selectedPage >= totalPages"
      class="flex gap-3 px-3 sm:px-6"
      :class="{ 'cursor-not-allowed': selectedPage >= totalPages }"
      aria-name="next-page"
      @click="nextPage"
    >
      <span class="text-grays-darkest hidden font-semibold sm:inline-flex">
        Next
      </span>
      <ZenniIconsIconChevronRight />
    </button>
  </nav>
</template>

<script lang="ts" setup>
import { usePagination } from '~/composables/usePagination'
const emit = defineEmits<{
  (event: 'update:currentPage', page: number): void
}>()

const props = defineProps({
  totalItems: {
    type: Number,
    default: 0,
  },
  pageSize: {
    type: Number,
    default: 0,
  },
  maxPages: {
    type: Number,
    default: 3,
  },
  currentPage: {
    type: Number,
    default: 0,
  },
})

const {
  totalPages,
  pages,
  selectedPage,
  startPage,
  endPage,
  next,
  prev,
  setPage: setPageFn,
  maxVisiblePages,
} = usePagination({
  totalItems: props.totalItems,
  currentPage: props.currentPage,
  pageSize: props.pageSize,
  maxPages: props.maxPages,
})

const nextPage = () => {
  next()
  emit('update:currentPage', selectedPage.value)
}

const prevPage = () => {
  prev()
  emit('update:currentPage', selectedPage.value)
}

const setPage = (page: number) => {
  setPageFn(page)
  emit('update:currentPage', selectedPage.value)
}

defineExpose({ setPage })
</script>
