import { ref, computed } from 'vue'
import { mapFilters } from './utils'
import { useEmployeeAlgolia, useGetAlgoliaSearchIndex } from '#imports'
import type { ZnFacet } from '~/types/filter'
import type { SearchResponse, SearchOptions, Hit } from '@algolia/client-search'

export const useProducts = <T>({
  hitsPerPage = 8,
  page = 0,
  indexName = '',
} = {}) => {
  const index = indexName || useGetAlgoliaSearchIndex()

  const hitsPerPageRef = ref(hitsPerPage)
  const pageRef = ref(page)
  const lastQueryRef = ref('')
  const lastFiltersRef = ref<Record<string, string[]>>({})
  const lastFacetsRef = ref<string[]>(['*'])
  const loading = ref(false)
  const result = ref<SearchResponse<T> | null>(null)

  const getProducts = async ({
    query = '',
    filters,
    facets,
    sortBy = '',
  }: {
    query?: string
    filters?: Record<string, string[]>
    facets?: string[]
    sortBy?: string // this should be the index of the replica index
  }) => {
    loading.value = true
    // cache last query and filters if they are not empty and different from the last ones
    if (query !== lastQueryRef.value) {
      lastQueryRef.value = query
    }
    if (filters && filters !== lastFiltersRef.value) {
      lastFiltersRef.value = filters
    }
    if (facets && facets !== lastFacetsRef.value) {
      lastFacetsRef.value = facets
    }
    const searchParams: { query: string; requestOptions: SearchOptions } = {
      query,
      requestOptions: {
        filters: filters ? mapFilters(filters) : undefined,
        hitsPerPage: hitsPerPageRef.value,
        page: pageRef.value,
        facets: facets || ['*'],
      },
    }

    // algolia sorting relies on the index name. There are one replica index per sorting option
    // see https://www.algolia.com/doc/guides/managing-results/refine-results/sorting/
    const { result: localResult, search } = useEmployeeAlgolia<T>(
      sortBy.length > 0 ? sortBy : index,
    )
    await search(searchParams)

    result.value = localResult.value
    if (pageRef.value > 0 && (result.value?.nbPages || 1) - 1 < pageRef.value) {
      if (result.value?.nbPages) {
        pageRef.value = result.value?.nbPages - 1
      } else {
        pageRef.value = 0
      }
    }
    loading.value = false
  }

  const nextPage = async () => {
    if (pageRef.value < (result.value?.nbPages || 1) - 1) {
      pageRef.value++
      await getProducts({
        query: lastQueryRef.value,
        filters: lastFiltersRef.value,
        facets: lastFacetsRef.value,
      })
    }
  }

  const prevPage = async () => {
    if (pageRef.value > 0) {
      pageRef.value--
      await getProducts({
        query: lastQueryRef.value,
        filters: lastFiltersRef.value,
        facets: lastFacetsRef.value,
      })
    }
  }

  const setPage = async (page: number, sortBy?: string) => {
    if (page >= 0 && page < (result.value?.nbPages || 0)) {
      pageRef.value = page
      await getProducts({
        query: lastQueryRef.value,
        filters: lastFiltersRef.value,
        facets: lastFacetsRef.value,
        sortBy,
      })
    }
  }

  const mapFacets = (facetValues?: Record<string, Record<string, number>>) => {
    const facets = (facetValues || result.value?.facets) ?? {}
    const facetsMapped: Record<string, any> = {}
    Object.entries(facets as any).forEach(([key, values]) => {
      facetsMapped[key] = {
        title: key,
        facet: {
          label: key,
          name: key,
          values: Object.entries(values as any).map(([value, count]) => ({
            label: value,
            value,
            productCount: count,
          })),
        },
      }
    })
    return facetsMapped
  }

  const mapFacetsToZnFacets = computed<Record<string, ZnFacet>>(() =>
    mapFacets(),
  )

  return {
    result,
    loading,
    getProducts,
    mapFacets,
    facets: mapFacetsToZnFacets,
    totalItems: computed(() => result.value?.nbHits ?? 0),
    totalPages: computed(() => result.value?.nbPages ?? 0),
    page: computed(() => pageRef.value + 1),
    pageSize: computed(() => hitsPerPageRef.value),
    hits: computed<Hit<T>[]>(() => result.value?.hits as Hit<T>[]),
    nextPage,
    prevPage,
    setPage,
  }
}
