export const formatSkuId = (sku: string) => {
  // format OH frame id to zenni frame id
  // if it is already zenni id, just return
  if (!sku) return ''

  if (sku.toLowerCase().startsWith('oh')) {
    return sku.slice(2)
  }

  return sku
}
