import { defineNuxtRouteMiddleware } from '#imports'

export default defineNuxtRouteMiddleware((to) => {
  if (!to.path.includes('/select-lens-type')) {
    return
  }

  const stepMap: { [key: string]: string } = {
    '1': 'prescriptionType',
    '1a': 'prescriptionSubType',
    '1b': 'prescriptionSubType',
    '1c': 'prescriptionSubType',
    '2': 'addPrescription',
    '2a': 'prescriptionEdit',
    '2b': 'prescriptionEdit',
    '2c': 'prescriptionEdit',
    '3': 'lensType',
    '4': 'lensIndex',
    '5': 'lensTint',
    '6': 'lensCoating',
  }

  const step = to.query.step as string
  if (step && stepMap[step]) {
    to.meta.currentStep = stepMap[step]
  }
})
