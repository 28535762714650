import { storeToRefs } from 'pinia'
import { useZenniAlgoliaSearch } from '#imports'
import { useGuestStore } from '~/store/guest'
import type { SearchOptions } from '@algolia/client-search'
export const useEmployeeAlgolia = <T>(indexName?: string) => {
  const { currentGift } = storeToRefs(useGuestStore())
  const {
    search: baseSearch,
    result,
    loading,
    searchGetters,
  } = useZenniAlgoliaSearch<T>(indexName)
  const search = async (
    queryParams: {
      query: string
      requestOptions: SearchOptions
    },
    includeFrameFilters: boolean = true,
  ) => {
    // we will asume that if no objectType is passed, then we are searching for products
    const filterHasObjectType =
      queryParams.requestOptions.filters?.includes('objectType')
    return await baseSearch(
      {
        ...queryParams,
        requestOptions: {
          ...queryParams.requestOptions,
          filters: `enabled:true AND ${
            queryParams.requestOptions.filters || ''
          }${!filterHasObjectType ? ' AND objectType:"product"' : ''}`,
        },
      },
      currentGift.value?.catalogFilter,
      includeFrameFilters ? currentGift.value?.frameFilter : undefined,
    )
  }
  return {
    search,
    result,
    loading,
    searchGetters,
  }
}
