import { ref, watch, computed } from 'vue'
import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import type {
  AddItemsToCartRequest,
  UpdateBundleItemsRequest,
  CartResponseSuccess,
  ShippingInformationRequest,
} from '@/types/cart'
import { useGuestCart } from '~/composables/api/useGuestCart'
import { persistedState } from '#imports'

export const useGuestCartStore = defineStore(
  'guestCart',
  () => {
    const cartId = ref<string | null>(null)
    const cart = ref<CartResponseSuccess | null>(null)
    const cartApi = useGuestCart()
    const requestError = ref<FetchError | null>(null)
    const loading = ref<boolean>(false)

    const prices = computed(() => cart.value?.prices)
    const itemCount = computed<number>(
      () => Object.keys(cart.value?.bundle_map ?? {}).length,
    )

    const createCart = async () => {
      loading.value = true
      try {
        const { data, error, status } = await cartApi.create()

        // This is a little non-ideal, but when useFetch is called client-side
        // the status and error refs may not be populated immediately so we need
        // to watch the request status
        watch(
          () => status.value,
          () => {
            if (status.value === 'success' && data.value) {
              cartId.value = data.value.cartId
            } else if (status.value === 'error' && error.value) {
              requestError.value = error.value
            }
          },
          { immediate: true },
        )
      } catch (e) {
        /* eslint-disable */
        console.log('[guestCart]: cart create error!')
        console.log(e)
        /* eslint-enable */
      } finally {
        loading.value = false
      }
    }

    const loadCart = async (id?: string) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.get((id || cartId.value) ?? '')

        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } catch (e) {
        /* eslint-disable */
        console.log('[guestCart]: error while loading cart')
        console.log(e)
        /* eslint-enable */
      } finally {
        loading.value = false
      }
    }

    watch(
      () => cartId.value,
      async () => {
        if (cartId.value && !cart.value) {
          await loadCart()
        }
      },
      { immediate: true },
    )

    const initCart = async () => {
      if (!cartId.value) {
        await createCart()
      }
      if (cartId.value && !cart.value) {
        await loadCart()
      }
    }

    const addItems = async (items: AddItemsToCartRequest) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.addItems(cart.value!.id, items)
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const removeBundle = async (bundleId: string) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.removeBundle(
          cart.value!.id,
          bundleId,
        )
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const updateBundle = async (
      bundleId: string,
      params: UpdateBundleItemsRequest,
    ) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.updateBundle(
          cart.value!.id,
          bundleId,
          params,
        )
        if (data.value) {
          cart.value = data.value
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const addShipping = async (params: ShippingInformationRequest) => {
      loading.value = true
      try {
        const { data, error } = await cartApi.addShipping(
          cart.value!.id,
          params,
        )
        if (data.value) {
          cart.value = data.value.cart
        } else if (error.value) {
          requestError.value = error.value
        }
      } finally {
        loading.value = false
      }
    }

    const reset = () => {
      cartId.value = null
      cart.value = null
    }

    return {
      cart,
      cartId,
      prices,
      itemCount,
      loading,
      createCart,
      loadCart,
      initCart,
      addItems,
      removeBundle,
      updateBundle,
      addShipping,
      reset,
    }
  },
  {
    persist: [
      {
        storage: persistedState.cookiesWithOptions({
          sameSite: 'strict',
        }),
        paths: ['cartId'],
      },
    ],
  },
)
