<template>
  <div id="fitmix-container" />
</template>

<script setup lang="ts">
import { defineComponent, onMounted, useNuxtApp } from '#imports'

defineComponent({
  name: 'FittingboxModal',
})

const { $fittingboxContext } = useNuxtApp()

onMounted(() => {
  $fittingboxContext.initFittingbox('fitmix-container')
})
</script>
