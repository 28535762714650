<template>
  <ZenniZnFilterFilterCategory
    v-if="facet"
    :title="title"
    :count="selectedProxy.length"
  >
    <ul class="flex flex-col">
      <li ref="inputsListItem" class="flex items-center gap-3 p-1 pl-4">
        <div class="flex flex-col">
          <span class="text-sm font-semibold">Min.</span>
          <ZnInput
            placeholder="$"
            size="lg"
            aria-label="Search-Min"
            :value="searchMin"
            @input="(v: string) => debounceUpdateSearch('min', v)"
          />
        </div>
        <span class="pt-8">to</span>
        <div class="flex flex-col">
          <span class="text-sm font-semibold">Max.</span>
          <ZnInput
            placeholder="$$$"
            size="lg"
            aria-label="Search-Max"
            :value="searchMax"
            @input="(v: string) => debounceUpdateSearch('max', v)"
          />
        </div>
      </li>
      <ZenniZnListItem
        v-for="{ value, productCount, label } in facetToShow?.values || []"
        :key="value"
        :aria-label="label"
        size="lg"
        @click="toggleSelected(value)"
      >
        <template #prefix>
          <ZnCheckbox
            :model-value="selectedProxy.includes(value)"
            class="pointer-events-none flex items-center p-1"
            :aria-label="label"
            :aria-name="value"
          />
        </template>
        <div class="flex gap-2">
          <span class="pl-1">{{ label }}</span>
          <ZenniZnCounter v-if="productCount">{{
            productCount
          }}</ZenniZnCounter>
        </div>
      </ZenniZnListItem>
      <li
        v-if="!showAll && facet.values.length > itemsToShow"
        class="mx-auto cursor-pointer font-semibold"
      >
        <a class="text-teal-500" @click="showAll = true"> Show more + </a>
      </li>
    </ul>
  </ZenniZnFilterFilterCategory>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { debounce } from 'lodash-es'
import type { CheckboxItemProps, CheckboxItemEmits } from '~/types/filter'

const inputsListItem = ref<HTMLElement | null>(null)

const emit = defineEmits<CheckboxItemEmits>()
const props = defineProps<CheckboxItemProps>()

// Ignore values that are in the facet values
const activeRange = props.selected
  .filter((s) => !props.facet?.values.some((v) => v.value === s))?.[0]
  ?.split('-')
const searchMin = ref(activeRange?.[0] || '')
const searchMax = ref(activeRange?.[1] || '')

const itemsToShow = 8

const showAll = ref(false)
const facetToShow = computed(() => {
  if (showAll.value) {
    return props.facet
  }
  return {
    ...props.facet,
    values: props.facet?.values.slice(0, itemsToShow) || [],
  }
})

const toggleSelected = (value: string) => {
  const index = selectedProxy.value.indexOf(value)
  if (index === -1) {
    selectedProxy.value.push(value)
  } else {
    selectedProxy.value.splice(index, 1)
  }
  emit('update:selected', selectedProxy.value)
}

const debounceUpdateSearch = debounce((type: 'min' | 'max', value: string) => {
  updateSearch(type, value)
}, 500)

const updateSearch = (type: 'min' | 'max', value: string) => {
  if (type === 'min') searchMin.value = value
  if (type === 'max') searchMax.value = value

  const valueToFilter = `${searchMin.value.length ? searchMin.value : '0'}-${
    searchMax.value
  }`

  // recreate the selected array with the old values related to facets and this new value
  const newSelected = []
  props.facet?.values.forEach((facetValue) => {
    const index = selectedProxy.value.indexOf(facetValue.value)
    if (index !== -1) {
      newSelected.push(selectedProxy.value[index])
    }
  })

  // be careful... don't add it if neither min nor max are set
  if (searchMin.value.length || searchMax.value.length)
    newSelected.push(valueToFilter)
  emit('update:selected', newSelected)
}

const selectedProxy = computed(() => props.selected)

watch(selectedProxy, () => {
  // if we detect a value in the selected proxy that is not related to any facet, we assume that is
  // a min-max value and we split it and set the min and max values
  const facetsValues = props.facet?.values.map((v) => v.value) || []
  const clearedSelected = selectedProxy.value.filter(
    (v) => !facetsValues.includes(v),
  )

  if (clearedSelected.length > 0) {
    const [min, max] = clearedSelected[0].split('-')
    searchMin.value = min
    searchMax.value = max
  } else {
    searchMin.value = ''
    searchMax.value = ''
  }

  const inputs = inputsListItem.value?.querySelectorAll('input')
  inputs?.forEach((input) => {
    input.blur()
  })
})
</script>
