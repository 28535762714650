<!-- eslint-disable tailwindcss/no-contradicting-classname -->
<template>
  <div
    class="flex w-64 cursor-pointer flex-col gap-2 tracking-wide no-underline"
    :tag="tag"
    @click.stop="emitClickWithSku"
  >
    <template v-if="loading">
      <div
        class="flex h-96 min-w-[250px] animate-pulse items-center justify-center"
      >
        <div class="bg-grays-lighter h-full w-full rounded-lg" />
      </div>
    </template>
    <template v-else>
      <div class="h-[30px]">
        <span
          v-if="isOutOfStock"
          class="bg-grays-light text-grays-darkest rounded-full px-2 py-1 text-sm font-semibold leading-[21px]"
          >Temporarily Out of Stock</span
        >
        <span
          v-else-if="isBestseller"
          class="rounded-full bg-[#EBF5F9] px-2 py-1 text-sm font-semibold leading-[21px] text-[#1B4B66]"
          >Bestseller</span
        >
      </div>
      <div
        v-show="imageLoading"
        class="flex h-[164px] items-center justify-center"
        data-testid="image-loading"
      ></div>
      <div
        v-show="!imageLoading"
        ref="imgRef"
        class="flex h-[164px] w-full items-center justify-center bg-[image:var(--image-url)] bg-contain bg-center bg-no-repeat transition-all duration-500 ease-in-out hover:bg-[image:var(--image-hover-url)] focus:bg-[image:var(--image-hover-url)]"
        role="img"
        :style="`--image-url: url(${imageToShow.image}); --image-hover-url: url(${imageToShow.hoverImage});`"
      />
      <div class="flex flex-col gap-1">
        <button
          v-if="showVto"
          class="border-grays-dark text-grays-darkest mx-auto mb-1 flex w-fit items-center justify-center gap-1.5 rounded-lg border px-4 py-2 text-sm font-semibold hover:opacity-90"
          @click.stop="handleTryOnClick"
        >
          <ZenniIconsIconCamera size="sm" class="mr-[6px]" />
          <span>Try on</span>
        </button>
        <div v-else class="h-[38px]" />
        <span
          class="text-grays-darkest truncate text-xl font-semibold leading-[30px]"
          data-testid="name"
          >{{ product?.name }}</span
        >
      </div>
      <span
        v-if="availableCredit < (product?.price ?? 0)"
        class="text-xl leading-[30px]"
        data-testid="price"
        >{{ itemPrice || '$0.00' }}</span
      >
      <span v-else class="text-grays-dark leading-[24px]"
        ><span class="text-purples-dark font-semibold">Free with credit</span>
        was {{ itemPrice || '$0.00' }}</span
      >
      <ZnColorDotSelector
        v-model="localThumbnail"
        data-testid="thumbnail"
        :colors="thumbnailColors as any"
        @update:model-value="thumbnailSelected"
      />

      <ZnRating v-if="productRating" :value="productRating" />
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref, toRefs, computed } from 'vue'
import { unrefElement } from '@vueuse/core'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import type { ZnColorDotMapType } from '~/components/Zenni/Plp/filter-types'
import type {
  ZnProductCardProps,
  ZnProductCardEmits,
  ZnProductCardImage,
} from './types'
// import { useGuestStore } from '~/store/guest'

// const { gift } = useGuestStore()

const imgRef = ref<HTMLImageElement | null>(null)
const imageLoading = ref(false)
const hoverImageLoaded = ref(false)

const emit = defineEmits<ZnProductCardEmits>()

const props = withDefaults(defineProps<ZnProductCardProps>(), {
  loading: false,
  availableCredit: 0,
  variants: () => [],
})
const { variants } = toRefs(props)

const sku = ref(variants.value?.[0]?.sku ?? '')
const product = computed(() => {
  return variants.value.find((variant) => variant.sku === sku.value)
})
const thumbnailColors = computed(
  () => variants.value?.map((variant) => variant.color) || [],
)

const itemPrice = computed(() => {
  // if (gift?.usage?.type === 'freeItem' || gift?.usage?.type == null) return ''

  return formatCurrency(product.value?.price ?? 0, 'en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
  })
})

const localThumbnail = ref<ZnColorDotMapType>(thumbnailColors.value?.[0] as any)

const imageToShow = computed<ZnProductCardImage>(() => {
  const img = {
    image: product.value?.image || '/img/product/image_placeholder.svg',
    hoverImage:
      product.value?.hoverImage ||
      product.value?.image ||
      '/img/product/image_placeholder.svg',
  }

  // @ts-ignore
  if (process.client) {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    imageLoading.value = true
    const bgImg = new Image()
    bgImg.onload = () => {
      imageLoading.value = false
    }
    bgImg.src = img.image
    // preload hover image
    const hoverImg = new Image()
    hoverImg.onload = () => {
      hoverImageLoaded.value = true
    }
    hoverImg.src = img.hoverImage ?? ''
  }
  return img
})

const thumbnailSelected = (color: string) => {
  // Fetch image from javascript to know when it was loaded and control loading state
  // @ts-ignore
  if (process.client) {
    imageLoading.value = true
    const bgImg = new Image()
    bgImg.onload = () => {
      unrefElement(imgRef as any).style.setProperty(
        '--image-url',
        `url("${imageToShow.value.image}")`,
      )
      imageLoading.value = false
    }
    bgImg.src = imageToShow.value.image
  }
  const colorIndex = thumbnailColors.value.indexOf(color)
  sku.value = variants.value[colorIndex].sku
}

const emitClickWithSku = () => {
  emit('goToProduct', { sku: sku.value, objectId: props.id })
}

const showVto = computed(() => {
  return props.vtoSkus?.includes(sku.value) ?? false
})

const handleTryOnClick = () => {
  return emit('tryOnClicked', sku.value)
}

const isOutOfStock = computed(() => {
  return props.outOfStockSkus?.[sku.value] ?? false
})

const isBestseller = computed(() => {
  return props.isBestSelling ?? false
})

const productRating = computed(() => {
  // TODO: remove hardcoded value when we have a rating
  return undefined
})
</script>
