<template>
  <section class="max-w-scrollable-section w-full lg:max-w-7xl">
    <h2 class="text-xl font-semibold">Safety Glasses</h2>
    <p class="pt-4 lg:pt-0">
      All glasses can be customized with anti-fog, scratch-resistant, and
      oil-resistant lenses.
    </p>
    <ZenniPlp
      is-landing-page
      :initial-filter="initialFilter"
      :available-credit="giftAmount"
    />
    <div class="border-grays-light border-b py-10 lg:hidden">
      <ZnButton class="mx-auto">
        <nuxt-link to="/products/list">View All</nuxt-link>
      </ZnButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useGuestStore } from '~/store/guest'
const { giftAmount } = useGuestStore()
const initialFilter = {} // TODO it can be safety glasses for example
</script>
