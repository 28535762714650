<template>
  <ZenniZnFilterFilterCategory
    v-if="categories.length"
    :initial-open="initialOpen"
    :title="title"
    :count="totalFilterCount"
  >
    <ul
      v-for="category in categories"
      :key="category.title"
      class="flex flex-col"
    >
      <span class="text-grays-dark pl-4">{{ category.title }}</span>
      <ZenniZnListItem
        v-for="{ value, productCount, label, imageurl } in category.facet
          .values"
        :key="value"
        :aria-label="label"
        size="lg"
        class="!h-14"
        @click="toggleSelected(category.facet.name || '', value)"
      >
        <template #prefix>
          <ZnCheckbox
            :model-value="selectedProxy[category.facet.name]?.includes(value)"
            class="pointer-events-none flex items-center p-1"
            :aria-label="label"
            :aria-name="value"
          />
        </template>
        <div class="justify-content flex items-center gap-2 pl-1">
          <img
            :src="imageurl"
            :alt="label"
            class="aspect-[116/93] max-w-[64px] object-contain"
          />
          <div class="flex gap-2">
            <span class="pl-1">{{ label }}</span>
            <ZenniZnCounter v-if="productCount">{{
              productCount
            }}</ZenniZnCounter>
          </div>
        </div>
      </ZenniZnListItem>
    </ul>
  </ZenniZnFilterFilterCategory>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import type {
  CheckboxCategoriesItemProps,
  CheckboxCategoriesItemEmits,
} from '~/types/filter'

const emit = defineEmits<CheckboxCategoriesItemEmits>()
const props = defineProps<CheckboxCategoriesItemProps>()

const totalFilterCount = computed(() => {
  return props.categories
    .map((cat) => cat.facet.name)
    .reduce((acc, key) => {
      return acc + (selectedProxy.value[key] || []).length
    }, 0)
})

const toggleSelected = (key: string, value: string) => {
  const values = [...(selectedProxy.value?.[key] || [])]
  const index = values.indexOf(value)
  if (index === -1) {
    values.push(value)
  } else {
    values.splice(index, 1)
  }
  emit('update:selected', { key, value: values })
}

const selectedProxy = computed(() => props.selected)
</script>
