import { useZenniFetch } from '@/composables/useZenniFetch'
import { useFetch, useSiteStoreCode } from '#imports'
import type { Prescription } from '@/types/prescription'

export const usePrescription = () => {
  const { isEyeBotSiteStore } = useSiteStoreCode()
  const get = async () => {
    return await useZenniFetch('/api/prescription')
  }

  const save = async (params: any) => {
    return await useZenniFetch('/api/prescription', {
      method: 'POST',
      body: params,
    })
  }

  const update = async (params: any) => {
    return await useZenniFetch('/api/prescription', {
      method: 'PUT',
      body: params,
    })
  }

  const validate = async (params: any) => {
    return await useFetch('/api/validatePrescriptionAndLens', {
      method: 'POST',
      body: params,
    })
  }

  const validatePrescriptionAndLens = (
    prescription: Prescription,
    prescriptionRange: any,
    framePrescriptionRange: any,
  ) => {
    return useFetch('/api/validate-prescription-lens', {
      method: 'POST',
      body: {
        prescription: isEyeBotSiteStore.value ? undefined : prescription,
        prescriptionRange,
        framePrescriptionRange,
      },
    })
  }

  return {
    get,
    save,
    update,
    validate,
    validatePrescriptionAndLens,
  }
}
