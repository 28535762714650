import { useFittingboxAvailabilityService } from '@/composables/api/useFittingboxAvailabilityService'
import type { FittingboxAvailability } from '~/types/FittingboxAvailability'

export const useFittingbox = () => {
  const { getAvailabilities } = useFittingboxAvailabilityService()

  const getAvailableFrames = async (productIds: string[]) => {
    const { data, error } = await getAvailabilities(productIds)

    if (error.value || !data.value) return []

    const availableFrames = (data.value as FittingboxAvailability[])
      ?.filter((frame: FittingboxAvailability) => frame.available)
      ?.map((frame: FittingboxAvailability) => frame.uid)

    return availableFrames
  }

  return {
    getAvailableFrames,
  }
}
