import { useGetAlgoliaSearchIndex } from '#imports'
import type { Prescription } from '@/types/prescription'

export const useFrameInfoApi = () => {
  const search = (prescription: Prescription, params: any) => {
    const algoliaIndex = useGetAlgoliaSearchIndex()
    return $fetch<any>(`/api/get-frame-info`, {
      query: {
        prescription,
        algoliaQueryParams: params,
        algoliaIndex,
      },
    })
  }
  const verifyFramePrescriptionType = (
    frameSku: string,
    prescription: Prescription,
  ) => {
    return search(prescription, {
      requestOptions: { filters: `sku:${frameSku}` },
    })
  }
  return { search, verifyFramePrescriptionType }
}
