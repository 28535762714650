<template>
  <div class="flex flex-col items-center space-y-4 text-center">
    <div class="border-grays-light rounded-full border object-cover">
      <slot name="image" />
    </div>
    <span class="text-grays-darkest w-fit truncate font-semibold">{{
      props.title
    }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
    default: '',
  },
})
</script>
