<template>
  <section
    v-if="selectedFilter"
    class="max-w-scrollable-section w-full lg:max-w-7xl"
  >
    <div class="flex flex-col gap-x-4 gap-y-2 md:flex-row md:items-center">
      <h2 class="text-xl font-semibold">
        Glasses under {{ formattedBudget }} ({{ totalItems }})
      </h2>
      <div
        class="text-purples-dark bg-purples-lightest flex w-fit items-center gap-3 rounded-full px-3 py-2"
      >
        <ZenniIconsIconGift size="xs" />
        <span class="font-semibold"
          >FREE with {{ formattedBudget }} credit applied in cart</span
        >
      </div>
    </div>
    <p class="pt-4 lg:pt-0">
      Includes frame and basic lens. Additional costs may apply for lens
      customizations and add-on’s.
    </p>
    <ZenniPlp
      is-landing-page
      hide-filters
      :initial-filter="initialFilter"
      :limit="9"
      :available-credit="giftAmount"
      @update:total-items="totalItems = $event"
    />

    <div class="border-grays-light border-b py-10">
      <ZnButton class="mx-auto">
        <nuxt-link :to="`/products/list/?${queryParams}`">View All</nuxt-link>
      </ZnButton>
    </div>
  </section>
</template>
<script setup lang="ts">
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { useGuestStore } from '~/store/guest'
import { computed, ref } from '#imports'
import { BUDGET_FILTERS } from '~/data/budget-filters'

const { gift, giftAmount } = useGuestStore()

const selectedFilter = BUDGET_FILTERS.sort((a, b) => b.price - a.price).find(
  (filter) => filter.price <= giftAmount,
)

const filterAmount = selectedFilter?.price ?? 0

const totalItems = ref(0)
const initialFilter: Record<string, string[]> =
  selectedFilter?.initialFilter ?? {}

const queryParams = computed(() => {
  const queryParts: string[] = []

  for (const [key, value] of Object.entries(initialFilter)) {
    value.forEach((item) =>
      queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`),
    )
  }

  return queryParts.join('&')
})

const formattedBudget = computed<string>(() =>
  formatCurrency(filterAmount ?? 0, 'en-US', {
    currency:
      gift?.usage?.type === 'recipientPays' ? gift?.usage.currency : 'USD',
    minimumFractionDigits: 2,
  }),
)
</script>
