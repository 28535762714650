<template>
  <ZnAccordionItem
    :class="['border-b', open ? 'pb-4' : 'pb-0']"
    :model-value="isTransitioning || open"
    @update:model-value="
      (isOpen: boolean) => {
        isTransitioning = true
        open = isOpen ?? false
      }
    "
  >
    <template #summary>
      <div class="flex items-center justify-between p-2">
        <div class="flex items-center">
          <p class="p-2 font-semibold" :class="titleClass">{{ title }}</p>
          <ZenniZnCounter
            v-if="count"
            class="!bg-grays-light !h-5 !px-4 text-xs font-semibold text-neutral-900"
            pill
            data-testid="filter-category-count"
            >{{ count }}</ZenniZnCounter
          >
        </div>
        <ZenniIconsIconChevronRight
          class="text-neutral-500"
          :class="open ? '-rotate-90' : 'rotate-90'"
        />
      </div>
    </template>
    <Transition
      enter-from-class="grid grid-rows-[0fr]"
      enter-to-class="grid grid-rows-[1fr]"
      leave-from-class="grid grid-rows-[1fr]"
      leave-to-class="grid grid-rows-[0fr]"
      @after-leave="isTransitioning = false"
      @after-enter="isTransitioning = false"
    >
      <div
        v-if="open"
        class="grid transition-[grid-template-rows] duration-300 ease-in-out"
      >
        <div class="overflow-hidden">
          <slot />
        </div>
      </div>
    </Transition>
  </ZnAccordionItem>
</template>

<script setup lang="ts">
import { ref, toRefs } from 'vue'
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  count: {
    type: Number,
    default: 0,
  },
  initialOpen: {
    type: Boolean,
  },
  titleClass: {
    type: String,
    default: '',
  },
})

// const open = ref(!!props.initialOpen)
const open = ref(true)
const isTransitioning = ref(false)

const { title, count } = toRefs(props)
</script>
