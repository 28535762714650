<template>
  <Teleport to="body">
    <div
      class="bg-grays-dark/50 fixed inset-0 z-50 min-h-screen"
      @click="emit('close')"
    >
      <aside
        class="absolute right-0 flex h-full w-full flex-col overflow-y-auto bg-white px-4 py-6 sm:max-w-lg"
        @click.stop
      >
        <div class="flex items-center justify-between">
          <h2 class="text-grays-darkest text-xl">
            <span v-show="step === 'explain'">How credit works</span>
            <span v-show="step === 'switch'" class="flex items-center">
              <a
                class="text-grays-darkest cursor-pointer self-start text-2xl font-normal"
                @click="step = 'select'"
                >&larr;</a
              >
              <span class="ml-6">Switch profile options</span>
            </span>
            <span v-show="step === 'select'">Switch profile</span>
          </h2>
          <ZenniIconsIconClose
            size="lg"
            class="text-grays-dark cursor-pointer"
            @click="emit('close')"
          />
        </div>
        <div v-show="step === 'explain'">
          <ProfileExplainCredit class="mt-8 px-4" />
          <ZnButton
            type="primary"
            class="mt-10 w-full text-center"
            @click="step = 'select'"
          >
            Back
          </ZnButton>
        </div>
        <div v-show="step === 'switch'">
          <ProfileConfirmSwitch
            class="mt-8"
            :target="targetGift ?? {}"
            @keep="handleSwitch()"
            @clear="handleSwitch(true)"
            @cancel="emit('close')"
          />
        </div>
        <div v-show="step === 'select'" class="flex flex-col justify-center">
          <ProfileSelect ref="profileSelectRef" class="mt-8" />
          <ZnButton
            type="primary"
            class="mt-10 w-full text-center"
            @click="handleConfirm()"
          >
            Confirm
          </ZnButton>
          <span
            class="text-teal-primary mt-6 cursor-pointer self-center underline underline-offset-2"
            @click="step = 'explain'"
          >
            How does credit work?
          </span>
        </div>
      </aside>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import Select from './Select.vue'
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  useRouter,
  usePrescription,
} from '#imports'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'

const { currentGift, guest, switchStoreToken, setCurrentGift } = useGuestStore()
const { itemCount, reset: resetCart, createCart } = useGuestCartStore()

const emit = defineEmits(['close'])

const profileSelectRef = ref<InstanceType<typeof Select>>()
const targetGift = computed(() => profileSelectRef.value?.targetGift)

const step = ref<'explain' | 'switch' | 'select'>('select')

const router = useRouter()
const { resetPrescription } = usePrescription()

const handleSwitch = (clearCart: boolean = false) => {
  if (profileSelectRef.value) {
    if (clearCart) {
      resetCart()
      createCart()
    }
    if (
      guest.loggedInToken &&
      targetGift.value &&
      targetGift.value.storeId !== currentGift?.storeId
    ) {
      switchStoreToken(targetGift.value.storeId!)
    }
    setCurrentGift(profileSelectRef.value.targetGiftId)

    router.push({ path: '/', force: true })

    // clear prescription while switching profile
    resetPrescription()
    emit('close')
  }
}

const handleConfirm = () => {
  if (
    profileSelectRef.value &&
    profileSelectRef.value.targetGiftId === currentGift?.id
  ) {
    emit('close')
  } else if (itemCount > 0) {
    step.value = 'switch'
  } else {
    handleSwitch()
  }
}

onMounted(() => {
  document.body.classList.add('overflow-hidden')
})

onUnmounted(() => {
  step.value = 'select'
  document.body.classList.remove('overflow-hidden')
})
</script>
