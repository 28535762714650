export const colorComparisonTable: { [key: string]: any } = {
  darkamber_80_: '#9A5D3E',
  darkgray_80_: '#4D5357',
  indigobluemirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #6bd8e3 8.82%, rgba(51, 110, 183, .97) 54.87%, #414099 83.33%)',
  silvermirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #e8f3f5 8.82%, rgba(146, 153, 154, .97) 38.54%, #243033 96.88%)',
  mossgreenmirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #e1ff68 0%, rgba(168, 206, 105, .97) 30.73%, #52685b 86.46%)',
  goldmirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #f8ffa6 0%, rgba(207, 191, 45, .97) 30.73%, #776017 86.46%)',
  flameredmirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #ffdd61 11.98%, rgba(226, 156, 55, .97) 47.4%, #991b21 100%)',
  rosegoldmirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #fff3d6 8.82%, rgba(224, 193, 167, .97) 38.54%, #9d796f 96.88%)',
  lavendermirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #fcdeff 0%, rgba(197, 151, 198, .97) 30.73%, #593868 86.46%)',
  skybluemirror:
    'radial-gradient(65.23% 65.23% at 50.03% 34.77%, #a4ffff 0%, rgba(54, 208, 218, .97) 30.73%, #2f7a99 86.46%)',
  fl_41migranelens25_: '#CCA2A1',
  fl_41migranelens50_: '#BB7877',
  fl_41migranelens80_: '#9F565A',
}
