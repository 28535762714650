<template>
  <ul class="flex items-center gap-2">
    <ZnSlider :items="chipValues">
      <template #item="{ data }">
        <li>
          <ZenniZnChip
            v-model="selectedValues"
            class="peer-next-checked:!ring-grays-medium whitespace-nowrap capitalize !ring-1"
            :input-props="{ value: (data as any).value }"
            @click.prevent="handleChipRemove(data as any)"
          >
            {{ (data as any).label }}
            <template #suffix>
              <ZenniIconsIconClose
                size="sm"
                class="hover:text-teal-primary active:text-teal-primary text-neutral-500 disabled:opacity-20"
              />
            </template>
          </ZenniZnChip>
        </li>
      </template>
      <template #suffix>
        <li v-if="selectedValues.length > 0">
          <button
            class="text-grays-darkest whitespace-nowrap font-semibold underline underline-offset-2"
            @click.prevent="clearFilters"
          >
            Clear filters
          </button>
        </li>
      </template>
    </ZnSlider>
  </ul>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'

export interface ZnFilterChipValue {
  label: string
  value: string
  category?: string
}

const emit = defineEmits<{
  (event: 'remove', value: ZnFilterChipValue): void
  (event: 'removeAll'): void
}>()

const props = defineProps({
  chipValues: {
    type: Array<ZnFilterChipValue>,
    required: false,
    default: [],
  },
})

const { chipValues } = toRefs(props)

const selectedValues = computed(() =>
  chipValues.value.map((item) => item.value),
)
const handleChipRemove = (item: ZnFilterChipValue) => {
  emit('remove', item)
}

const clearFilters = () => {
  emit('removeAll')
}
</script>
