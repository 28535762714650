<template>
  <ZenniZnFilterFilterCategory
    v-if="facet"
    title="Gender"
    :count="selectedProxy.length"
  >
    <ul class="flex flex-col">
      <ZenniZnListItem
        v-for="{ value, productCount, label } in computedFacetValues"
        :key="value"
        :aria-label="label"
        size="lg"
        @click="toggleSelected(value)"
      >
        <template #prefix>
          <ZnCheckbox
            :model-value="selectedProxy.includes(value)"
            class="pointer-events-none flex items-center p-1"
            :aria-label="label"
            :aria-name="value"
          />
        </template>
        <div class="flex gap-2">
          <span class="pl-1">{{ label }}</span>
          <ZenniZnCounter v-if="productCount">{{
            productCount
          }}</ZenniZnCounter>
        </div>
      </ZenniZnListItem>
      <li
        v-if="!showAll && facet.values.length > itemsToShow"
        class="mx-auto cursor-pointer font-semibold"
      >
        <a class="text-teal-primary" @click="showAll = true"> Show more + </a>
      </li>
    </ul>
  </ZenniZnFilterFilterCategory>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { maxBy, sumBy } from 'lodash-es'
import type {
  CheckboxItemProps,
  CheckboxItemEmits,
} from '~/components/Zenni/Plp/filter-types'
import { KIDS_GENDER_GROUP_STRING } from '~/data/categories'

const emit = defineEmits<CheckboxItemEmits>()
const props = defineProps<CheckboxItemProps>()

const itemsToShow = 8

const showAll = ref(false)
const facetToShow = computed(() => {
  if (showAll.value) {
    return props.facet
  }
  return {
    ...props.facet,
    values: props.facet?.values.slice(0, itemsToShow),
  }
})

const toggleSelected = (value: string) => {
  const index = selectedProxy.value.indexOf(value)
  if (index === -1) {
    selectedProxy.value.push(value)
  } else {
    selectedProxy.value.splice(index, 1)
  }
  emit('update:selected', selectedProxy.value)
}

const selectedProxy = computed(() => props.selected)

const computedFacetValues = computed(() => {
  const withWomen = facetToShow.value?.values?.filter(
    (facet) => facet.value.toLowerCase() === 'women_s',
  )

  const withMen = facetToShow.value?.values?.filter(
    (facet) => facet.value.toLowerCase() === 'men_s',
  )

  const withUnisex = facetToShow.value?.values?.filter(
    (facet) => facet.value.toLowerCase() === 'unisex',
  )

  const withKids = facetToShow.value?.values?.filter(
    (facet) =>
      facet.value.toLowerCase() === 'girl_s' ||
      facet.value.toLowerCase() === 'boy_s' ||
      facet.value.toLowerCase() === 'unisex_kids',
  )

  const women = {
    label: 'Women',
    value: 'women_s',
    productCount:
      maxBy(withWomen, (item: any) => item.productCount)?.productCount ?? 0,
  }

  const men = {
    label: 'Men',
    value: 'men_s',
    productCount:
      maxBy(withMen, (item: any) => item.productCount)?.productCount ?? 0,
  }

  const unisex = {
    label: 'Unisex',
    value: 'unisex',
    productCount:
      maxBy(withUnisex, (item: any) => item.productCount)?.productCount ?? 0,
  }

  const kids = {
    label: 'Kids',
    value: KIDS_GENDER_GROUP_STRING,
    productCount: sumBy(withKids, 'productCount'),
  }

  return [women, men, unisex, kids].filter((x) => x.productCount > 0)
})
</script>
