import { useFetch } from 'nuxt/app'
import { useRuntimeConfig } from '#imports'

export const useFittingboxAvailabilityService = () => {
  const fittingboxApiKey = useRuntimeConfig().public?.fittingboxApiKey ?? ''
  const fittingboxUrl = useRuntimeConfig().public?.fittingboxUrl ?? ''

  const getAvailabilities = (productIds: string[]) => {
    const url = `${fittingboxUrl}/glasses-metadata/availability?apiKey=${fittingboxApiKey}&uidList=${productIds.join(
      ',',
    )}`

    return useFetch(url)
  }

  return {
    getAvailabilities,
  }
}
