<template>
  <div class="flex min-h-screen flex-col">
    <Header />
    <main class="relative flex flex-1 flex-col">
      <NuxtLoadingIndicator />
      <div class="flex flex-1">
        <slot />
      </div>
    </main>
    <Footer />
  </div>
</template>
