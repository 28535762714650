export const BUDGET_FILTERS: {
  price: number
  initialFilter: Record<string, string[]>
}[] = [
  {
    price: 25,
    initialFilter: {
      Is_Under_25_Limit: ['true'],
    },
  },
  {
    price: 50,
    initialFilter: {
      Is_Under_50_Limit: ['true'],
    },
  },
  {
    price: 75,
    initialFilter: {
      Is_Under_75_Limit: ['true'],
    },
  },
  {
    price: 100,
    initialFilter: {
      Is_Under_100_Limit: ['true'],
    },
  },
  {
    price: 125,
    initialFilter: {
      Is_Under_125_Limit: ['true'],
    },
  },
  {
    price: 150,
    initialFilter: {
      Is_Under_150_Limit: ['true'],
    },
  },
  {
    price: 175,
    initialFilter: {
      Is_Under_175_Limit: ['true'],
    },
  },
  {
    price: 200,
    initialFilter: {
      Is_Under_200_Limit: ['true'],
    },
  },
]
