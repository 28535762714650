import { ref } from 'vue'
import type { GetStockInformationParams } from './types'

export const useStockInformation = () => {
  const lastSkusRef = ref<string[]>([])
  const loading = ref(false)
  const result = ref<Record<string, boolean>>({})

  const getStockInformation = async ({ skus }: GetStockInformationParams) => {
    loading.value = true
    // cache last skus if they are not empty and different from the last ones
    if (skus !== lastSkusRef.value) {
      lastSkusRef.value = skus
    }
    const stockInformation = await $fetch<Record<string, boolean>>(
      `/api/get-stock-information`,
      {
        query: {
          sku: skus.join(','),
        },
      },
    )

    result.value = stockInformation
    loading.value = false
  }

  return {
    result,
    loading,
    getStockInformation,
  }
}
