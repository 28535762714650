<template>
  <div class="flex flex-col">
    <template v-if="loading">
      <slot name="loading" />
    </template>
    <template v-else>
      <CheckboxItem
        v-if="groupedFilters.freeGlasses.length"
        class="order-0"
        title="Free Glasses"
        title-class="text-purples-dark"
        :facet="groupedFilters.freeGlasses[0].facet"
        :categories="groupedFilters.freeGlasses as any[]"
        :selected="
          filtersSelected[
            groupedFilters.freeGlasses[0].facet.label as Filters
          ] || []
        "
        initial-open
        @update:selected="
          (selected: string[]) =>
            updateSelected(
              groupedFilters.freeGlasses[0].facet.label as Filters,
              selected,
            )
        "
      />
      <template
        v-for="(filterCat, i) in Object.keys(groupedFilters.single)"
        :key="`filter-${i}`"
      >
        <component
          :is="componentMapper[filterCat]"
          v-if="componentMapper[filterCat]"
          :class="
            filterOrderClass[groupedFilters.single[filterCat]?.filterOrder ?? 1]
          "
          :title="groupedFilters.single[filterCat].title"
          :facet="groupedFilters.single[filterCat].facet"
          :selected="
            filtersSelected[filterCat as keyof typeof filtersSelected] || []
          "
          :initial-open="
            groupedFilters.single[filterCat].filterOrder === 1 ? true : false
          "
          @update:selected="
            (selected: string[]) =>
              updateSelected(filterCat as Filters, selected)
          "
        />
      </template>
      <CheckboxCategoriesItem
        v-if="groupedFilters.grouped.length"
        :class="filterOrderClass[groupedFilters.grouped[0]?.filterOrder ?? 1]"
        title="Frame Style"
        :categories="groupedFilters.grouped as any[]"
        :selected="filtersSelected"
        :initial-open="
          groupedFilters.grouped[0].filterOrder === 1 ? true : false
        "
        @update:selected="
          ({ key, value }) => updateSelected(key as Filters, value)
        "
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref, toRefs, computed } from 'vue'
import CheckboxItem from './CheckboxItem/CheckboxItem.vue'
import HasGenderItem from './HasGenderItem/HasGenderItem.vue'
import HasPrescriptionItem from './HasPrescriptionItem/HasPrescriptionItem.vue'
import CheckboxRangeItem from './CheckboxRangeItem/CheckboxRangeItem.vue'
import ChipSelector from './ChipSelector/ChipSelector.vue'
import ColorDotItem from './ColorDotItem/ColorDotItem.vue'
import CheckboxCategoriesItem from './CheckboxCategoriesItem/CheckboxCategoriesItem.vue'
import { filterOrderClass as filterOrderClassImport } from './utils'
import type {
  Filter,
  Filters,
  SelectedFilters,
  ZnFilterProps,
} from '~/components/Zenni/Plp/filter-types'
import { BUDGET_FILTERS } from '~/data/budget-filters'
import { useGuestStore } from '~/store/guest'
import type { ZnFilterEmits } from './types'

const { giftAmount } = useGuestStore()

const selectedFilter = BUDGET_FILTERS.sort((a, b) => b.price - a.price).find(
  (filter) => filter.price <= giftAmount,
)

const componentMapper: any = {
  gender: HasGenderItem,
  Frame___Prescription_types_search: HasPrescriptionItem,
  price: CheckboxItem,
  size: ChipSelector,
  frameStyle: CheckboxItem,
  color: ColorDotItem,
  brand: CheckboxItem,
  material: CheckboxItem,
  Price_search: CheckboxRangeItem,
  Frame___Gender_search: HasGenderItem,
  Frame___Size_search: ChipSelector,
  Frame___Shape_search: CheckboxItem,
  Main_Color_search: ColorDotItem,
  Brand_search: CheckboxItem,
  Frame___Material_search: CheckboxItem,
  Frame___Rim_type_search: CheckboxItem,
  Frame___Features_search: CheckboxItem,
  Is_Under_25_Limit: CheckboxItem,
  Is_Under_50_Limit: CheckboxItem,
  Is_Under_75_Limit: CheckboxItem,
  Is_Under_100_Limit: CheckboxItem,
  Is_Under_125_Limit: CheckboxItem,
  Is_Under_150_Limit: CheckboxItem,
  Is_Under_175_Limit: CheckboxItem,
  Is_Under_200_Limit: CheckboxItem,
}

const emit = defineEmits<ZnFilterEmits>()
const props = withDefaults(defineProps<ZnFilterProps>(), {
  filters: () => ({}) as Filter,
  selectedFilters: () => ({}) as SelectedFilters,
  loading: false,
  grouped: () => [],
  freeGlasses: () => [],
})

const { selectedFilters } = toRefs(props)
const filtersSelected = ref<SelectedFilters>(selectedFilters.value)

const filterOrderClass = filterOrderClassImport as {
  [key: number]: string
}

const groupedFilters = computed(() => {
  const filters: {
    grouped: Filter[]
    freeGlasses: Filter[]
    single: Record<string, Filter>
  } = {
    grouped: [],
    freeGlasses: [],
    single: {},
  }
  for (const key of Object.keys(props.filters)) {
    if (props.grouped.includes(key)) {
      filters.grouped.push(props.filters[key as keyof Filter] as Filter)
    } else if (props.freeGlasses.includes(key)) {
      if (selectedFilter?.initialFilter[key]?.length) {
        const filter = props.filters[key as keyof Filter] as Filter
        filters.freeGlasses.push({
          ...filter,
          facet: {
            ...filter.facet,
            values: filter.facet.values.filter((val) => val.value === 'true'),
          },
        })
      }
    } else {
      filters.single[key] = props.filters[key as keyof Filter] as Filter
    }
  }

  return filters
})

const updateSelected = (filterCat: Filters, selected: string[]) => {
  if (!selected.length) {
    delete filtersSelected.value[filterCat]
  } else {
    filtersSelected.value[filterCat] = selected
  }
  emit('update:selectedFilters', filtersSelected.value)
  emit('apply', filtersSelected.value)
}
</script>
