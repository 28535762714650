<template>
  <nuxt-link
    :href="href"
    class="inline-block no-underline"
    data-testid="frame-style-card"
  >
    <div
      class="flex h-[200px] w-[196px] flex-auto shrink-0 flex-col rounded-md text-center"
    >
      <div
        class="border-grays-light mx-auto h-[150px] w-[150px] flex-1 items-center justify-center rounded-full border"
      >
        <div class="aspect-[16/10] h-full w-full items-center px-4">
          <img
            :src="image"
            :alt="alt || title"
            :img-attrs="imgAttrs"
            class="h-full w-full rounded-md object-contain"
            data-testid="frame-style-image"
          />
        </div>
      </div>
      <span
        class="my-4 font-semibold text-neutral-900"
        data-testid="frame-style-title"
      >
        {{ title }}
      </span>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import type { ZnFrameStyleCardProps } from './types'

const props = withDefaults(defineProps<ZnFrameStyleCardProps>(), {
  alt: '',
})
const { image, alt, imgAttrs, title, href } = toRefs(props)
</script>
