<template>
  <input
    v-model="valueProxy"
    class="peer absolute h-0 w-0 appearance-none border-0 outline-none"
    type="checkbox"
    v-bind="inputProps"
  />
  <label
    :class="[
      'outline-secondary-600 peer-next-checked:ring-2 peer-next-checked:ring-teal-primary peer-next-hover:ring-teal-200 peer-next-active:ring-teal-300 peer-next-disabled:cursor-not-allowed peer-next-disabled:bg-disabled-100 peer-next-disabled:opacity-50 peer-next-disabled:ring-1 peer-next-disabled:ring-disabled-200 peer-next-disabled:hover:ring-disabled-200 peer-next-checked:hover:ring-teal-primary peer-next-checked:active:ring-teal-primary peer-next-focus-visible:outline inline-flex cursor-pointer items-center justify-center rounded-full outline-offset-2 ring-1 ring-inset ring-neutral-200 transition duration-300 hover:bg-teal-100 active:bg-teal-200',
      'gap-2 py-2 text-base',
      [$slots.prefix ? 'pl-3' : 'pl-4', $slots.suffix ? 'pr-3' : 'pr-4'],
    ]"
    data-testid="chip"
    v-bind="$attrs"
  >
    <slot v-if="$slots.prefix" name="prefix" />
    <slot />
    <slot v-if="$slots.suffix" name="suffix" />
  </label>
</template>
<script setup lang="ts">
import { type PropType, type InputHTMLAttributes } from 'vue'
import { computed, toRefs } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Array, Boolean] as PropType<InputHTMLAttributes['checked']>,
    default: false,
  },
  inputProps: {
    type: Object as PropType<InputHTMLAttributes>,
    default: null,
  },
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: InputHTMLAttributes['checked']): void
}>()

const { modelValue } = toRefs(props)

const valueProxy = computed({
  get: () => modelValue?.value,
  set: (value) => emit('update:modelValue', value),
})
</script>
