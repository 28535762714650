<template>
  <section v-if="computedHits.length">
    <div class="flex flex-col justify-center">
      <ProductListSection
        :title="title"
        class="mx-auto max-w-7xl"
        :try-on-available-frames="tryOnAvailableFrames"
        :products="computedHits"
        :active-image="activeImage"
      />

      <ZnButton class="border-teal-dark mx-auto" type="secondary">
        <nuxt-link
          :to="{
            path: `/products/list${category ? `/${category}` : ''}`,
            query: { activeImage: activeImage },
          }"
          >Shop all</nuxt-link
        >
      </ZnButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { normalizationFrameHit } from '@/utils/normalize/algoliaSearchNormalize'
import { ref, computed, watchEffect } from '#imports'
// import { useGuestStore } from '~/store/guest'
import { useFittingbox } from '~/composables/useFittingbox'
import { formatSkuId } from '~/utils/skuIdGetters'
import { useEmployeeAlgolia } from '~/composables/useEmployeeAlgolia'
import type { ZnAlgoliaProduct } from '../../Zenni/Plp/algolia-types'

const { search, result } = useEmployeeAlgolia<ZnAlgoliaProduct>()
const { getAvailableFrames } = useFittingbox()

// TODO: We should get the available balance from the gift store
// const { gift } = useGuestStore()

const props = withDefaults(
  defineProps<{
    title: string
    filter?: string
    category?: string
    activeImage?: 'tinted'
    searchQuery?: string
  }>(),
  {
    filter: '',
    category: undefined,
    activeImage: undefined,
    searchQuery: '',
  },
)

// TODO: We should reconfigure the filters when we have more data about how to filter the popular products
const maxHits = 4
const popularProductsSearchParams = {
  query: props.searchQuery,
  requestOptions: {
    filters: `family:frame AND objectType:"product" AND Badges:'bestselling' AND NOT Badges:'kids'${
      props.filter ? ` AND ${props.filter}` : '' // TODO: we should add the bestseller field as true when it is available in algolia
    }`,
    hitsPerPage: 20,
  },
}
const randomItems = (values: any[], pickNumber: number) => {
  if (values.length <= pickNumber) return values
  const indexes = Array.from({ length: values.length }, (_, i) => i)
  const indexesSuffle = indexes.sort(() => 0.5 - Math.random())
  return indexesSuffle.slice(0, pickNumber).map((i) => values[i])
}
// SSR
await search(popularProductsSearchParams)

const tryOnAvailableFrames = ref<string[]>([])

const computedHits = computed<any[]>(() => {
  if (!result.value) return []
  const randomHits = randomItems(result.value.hits, maxHits)
  return randomHits.map((hit) => normalizationFrameHit(hit as any))
})

const refreshTryOnList = async () => {
  const availableFrames = await getAvailableFrames(
    computedHits.value.map((hit) => {
      return formatSkuId(hit.sku)
    }),
  )
  tryOnAvailableFrames.value = availableFrames
}

watchEffect(async () => {
  if (computedHits.value.length && process.client) {
    await refreshTryOnList()
  }
})
</script>
