<template>
  <details :open="modelValue" data-testid="accordion-item">
    <summary
      class="focus-visible:outline-offset cursor-pointer list-none focus-visible:rounded-sm focus-visible:outline [&::-webkit-details-marker]:hidden"
      @click.prevent="emit('update:modelValue', !modelValue)"
    >
      <slot name="summary" />
    </summary>
    <slot />
  </details>
</template>
<script setup lang="ts">
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits<{
  (event: 'update:modelValue', open: boolean): void
}>()
</script>
