<template>
  <transition
    enter-active-class="transition duration-200 ease-out"
    leave-active-class="transition duration-200 ease-out"
    enter-from-class="opacity-0 translate-y-10"
    enter-to-class="opacity-100 translate-y-0"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-10"
  >
    <div
      v-if="isOpen"
      ref="modalRef"
      aria-modal="true"
      data-testid="modal"
      tabindex="-1"
      class="fixed inset-0 z-50 m-auto h-full max-h-screen w-full max-w-full overflow-y-auto border border-neutral-100 bg-white p-6 pt-10 shadow-xl outline-none lg:p-10"
      :class="['!m-0 !p-0 md:!m-0 md:!max-w-full md:!p-0', 'px-6']"
      @keydown.esc="onEscKeyDown"
    >
      <summary
        class="fixed z-10 inline-flex h-14 w-full items-center justify-between gap-2 border-b bg-white py-2 pl-4"
      >
        <span class="text-xl font-semibold">Filters</span>
        <ZenniIconsIconClose class="mr-4 cursor-pointer" @click="close()" />
      </summary>
      <div class="flex flex-col pb-[88px] pt-14">
        <CheckboxItem
          v-if="groupedFilters.freeGlasses.length"
          class="order-0"
          title="Free Glasses"
          title-class="text-purples-dark"
          :facet="groupedFilters.freeGlasses[0].facet"
          :categories="groupedFilters.freeGlasses as any[]"
          :selected="
            filtersSelected[
              groupedFilters.freeGlasses[0].facet.label as Filters
            ] || []
          "
          initial-open
          @update:selected="
            (selected: string[]) =>
              updateSelected(
                groupedFilters.freeGlasses[0].facet.label as Filters,
                selected,
              )
          "
        />
        <template
          v-for="(filterCat, i) in Object.keys(groupedFilters.single)"
          :key="`filter-${i}`"
        >
          <component
            :is="componentMapper[filterCat]"
            v-if="componentMapper[filterCat]"
            :class="
              filterOrderClass[
                groupedFilters.single[filterCat]?.filterOrder ?? 1
              ]
            "
            :title="groupedFilters.single[filterCat]?.title"
            :facet="groupedFilters.single[filterCat]?.facet"
            :selected="
              filtersSelected[filterCat as keyof typeof filtersSelected] || []
            "
            @update:selected="
              (selected: string[]) =>
                updateSelected(filterCat as Filters, selected)
            "
          />
        </template>
        <CheckboxCategoriesItem
          v-if="groupedFilters.grouped.length"
          :class="filterOrderClass[groupedFilters.grouped[0]?.filterOrder ?? 1]"
          title="Frame Style"
          :categories="groupedFilters.grouped as any[]"
          :selected="filtersSelected"
          @update:selected="
            ({ key, value }) => updateSelected(key as Filters, value)
          "
        />
      </div>
      <footer
        class="fixed bottom-0 left-0 z-10 inline-flex h-[88px] w-full gap-3 border-t bg-white p-4"
      >
        <button
          class="text-grays-darkest border-grays-dark sm:text-md flex-1 rounded-lg border bg-white px-5 py-4 font-semibold md:text-lg"
          @click="clearFilters"
        >
          Clear all
        </button>
        <button
          class="bg-teal-primary sm:text-md flex-1 rounded-lg px-5 py-4 font-semibold text-white md:text-lg"
          @click="applyFilters"
        >
          Apply filter ({{ totalFiltersApplied }})
        </button>
      </footer>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'
import CheckboxItem from './CheckboxItem/CheckboxItem.vue'
import HasGenderItem from './HasGenderItem/HasGenderItem.vue'
import HasPrescriptionItem from './HasPrescriptionItem/HasPrescriptionItem.vue'
import CheckboxRangeItem from './CheckboxRangeItem/CheckboxRangeItem.vue'
import ChipSelector from './ChipSelector/ChipSelector.vue'
import ColorDotItem from './ColorDotItem/ColorDotItem.vue'
import CheckboxCategoriesItem from './CheckboxCategoriesItem/CheckboxCategoriesItem.vue'
import { filterOrderClass as filterOrderClassImport } from './utils'
import type {
  Filter,
  Filters,
  SelectedFilters,
  ZnFilterModalProps,
} from '~/components/Zenni/Plp/filter-types'
import { BUDGET_FILTERS } from '~/data/budget-filters'
import { useGuestStore } from '~/store/guest'
import type { ZnFilterEmits } from './types'

const { giftAmount } = useGuestStore()

const selectedFilter = BUDGET_FILTERS.sort((a, b) => b.price - a.price).find(
  (filter) => filter.price <= giftAmount,
)

const filterOrderClass = filterOrderClassImport as {
  [key: number]: string
}

const emit = defineEmits<ZnFilterEmits>()
const props = withDefaults(defineProps<ZnFilterModalProps>(), {
  isOpen: false,
  filters: () => ({}) as Filter,
  selectedFilters: () => ({}) as SelectedFilters,
  grouped: () => [],
  freeGlasses: () => [],
})

const close = () => {
  emit('close')
}

const modalRef = ref<HTMLElement>()

onClickOutside(modalRef, () => {
  close()
})

const onEscKeyDown = () => {
  close()
}

const componentMapper: any = {
  gender: HasGenderItem,
  Frame___Prescription_types_search: HasPrescriptionItem,
  price: CheckboxItem,
  size: ChipSelector,
  frameStyle: CheckboxItem,
  color: ColorDotItem,
  brand: CheckboxItem,
  material: CheckboxItem,
  Price_search: CheckboxRangeItem,
  Frame___Gender_search: HasGenderItem,
  Frame___Size_search: ChipSelector,
  Frame___Shape_search: CheckboxItem,
  Main_Color_search: ColorDotItem,
  Brand_search: CheckboxItem,
  Frame___Material_search: CheckboxItem,
  Frame___Rim_type_search: CheckboxItem,
  Frame___Features_search: CheckboxItem,
  Is_Under_25_Limit: CheckboxItem,
  Is_Under_50_Limit: CheckboxItem,
  Is_Under_75_Limit: CheckboxItem,
  Is_Under_100_Limit: CheckboxItem,
  Is_Under_125_Limit: CheckboxItem,
  Is_Under_150_Limit: CheckboxItem,
  Is_Under_175_Limit: CheckboxItem,
  Is_Under_200_Limit: CheckboxItem,
}

const filtersSelected = ref<SelectedFilters>(props.selectedFilters)

const totalFiltersApplied = computed(() => {
  return Object.keys(filtersSelected.value).reduce(
    (acc, curr) => acc + ((filtersSelected.value as any)[curr]?.length || 0),
    0,
  )
})

const groupedFilters = computed(() => {
  const filters: {
    grouped: Filter[]
    freeGlasses: Filter[]
    single: Record<string, Filter>
  } = {
    grouped: [],
    freeGlasses: [],
    single: {},
  }
  for (const key of Object.keys(props.filters)) {
    if (props.grouped.includes(key)) {
      filters.grouped.push(props.filters[key as keyof Filter] as Filter)
    } else if (props.freeGlasses.includes(key)) {
      if (selectedFilter?.initialFilter[key]?.length) {
        const filter = props.filters[key as keyof Filter] as Filter
        filters.freeGlasses.push({
          ...filter,
          facet: {
            ...filter.facet,
            values: filter.facet.values.filter((val) => val.value === 'true'),
          },
        })
      }
    } else {
      filters.single[key] = props.filters[key as keyof Filter] as Filter
    }
  }

  return filters
})

const updateSelected = (filterCat: Filters, selected: string[]) => {
  if (!selected.length) {
    delete filtersSelected.value[filterCat]
  } else {
    filtersSelected.value[filterCat] = selected
  }
  emit('update:selectedFilters', filtersSelected.value)
}

const clearFilters = () => {
  filtersSelected.value = {} as SelectedFilters
  emit('update:selectedFilters', filtersSelected.value)
}

const applyFilters = () => {
  emit('apply', filtersSelected.value)
  close()
}
</script>
