import { colorComparisonTable } from '../../components/Lens/SelectLensTint/constant'

const lensTypeLabel: { [key: string]: string } = {
  clear: 'Clear',
  blokz: 'Blokz blue light',
  sunglasses: 'Sunglasses',
  specialty: 'Specialty lenses',
  photochromic: 'Transitions',
}

const normalizationLensType = (lensType: string) => {
  if (!lensType) return ''

  return lensTypeLabel[lensType]
}

const normalizationTintColor = (tintColor: string) => {
  return colorComparisonTable[tintColor] || ''
}

const normalizationPhotochromicTintColor = (lensIndex: any) => {
  console.log({ lensIndex })
  let tintCSSClass = ''
  if (!lensIndex) {
    return tintCSSClass
  }
  const lensColorClass = (lensIndex.lensColor || lensIndex.Lens___Color) ?? ''
  const photochromicType =
    lensIndex.photochromicType || lensIndex.Photochromic_type
  const sunglassType = lensIndex.sunglassType || lensIndex.Sunglasses_Type

  if (photochromicType?.includes('transitionssignature')) {
    tintCSSClass = `transitionsignaturegen8-${lensColorClass} photochromic-animation` // 'TransitionSignatureGen8'
  } else if (photochromicType?.includes('transitionsxtp')) {
    tintCSSClass = `transitionxactivepolarized-${lensColorClass} photochromic-animation` // 'TransitionXActivePolarized'
  } else if (photochromicType?.includes('transitions_gens')) {
    tintCSSClass = `photochromic-${lensColorClass} photochromic-animation` // 'Transition-Gens'
  } else if (photochromicType?.includes('eyeqlenzanti_infrared')) {
    tintCSSClass = `eyeqlenz-${lensColorClass} photochromic-animation` // 'EyeQLenz'
  } else if (photochromicType?.includes('transitionsxtractive')) {
    tintCSSClass = `transitionxactivenewgen-${lensColorClass} photochromic-animation` // 'TransitionXActiveNewGen'
  } else if (photochromicType?.includes('transitiondrivewear')) {
    tintCSSClass = `transitionsdrivewear-olivegreen drivewear-animation` // 'BlueBlockLensPhotochromic', 'Photochromic'
  } else if (sunglassType?.includes('polarized')) {
    tintCSSClass = `polarized-${lensColorClass}` // 'Polarized'
  }
  return tintCSSClass
}

export {
  normalizationLensType,
  normalizationTintColor,
  normalizationPhotochromicTintColor,
}
