<template>
  <div class="bg-teal-lightest h-[499px] w-full lg:h-[390px]">
    <section
      class="bg-grays-lighter relative m-auto flex h-full w-full max-w-screen-xl items-end bg-[url('/img/landing/vision/hero-vision.jpeg')] bg-cover bg-top bg-no-repeat px-5 pb-14 lg:bg-[url('/img/landing/hero-vision.png')] lg:px-20"
    >
      <div
        class="mt-auto flex w-full max-w-sm flex-col gap-2 rounded bg-white p-6 drop-shadow-lg lg:p-10"
      >
        <div class="flex flex-col">
          <h1
            class="text-teal-primary text-[32px] font-semibold tracking-wide md:text-3xl"
          >
            Stylish Eyewear for Everyone
          </h1>
        </div>
        <h3 class="text-grays-dark">
          Now Available in Prescription and Non-Prescription Options
        </h3>
      </div>
      <span class="absolute bottom-2 right-2 text-sm text-white"
        >Style no. 2020123, 195421</span
      >
    </section>
  </div>
</template>

<script setup lang="ts"></script>
