import validate from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45persist_45utm_45global from "/usr/app/rdp-employee/middleware/00.persist-utm.global.ts";
import _01_45guest_45init_45global from "/usr/app/rdp-employee/middleware/01.guest-init.global.ts";
import _02_45refresh_45guest_45global from "/usr/app/rdp-employee/middleware/02.refresh-guest.global.ts";
import lens_45step_45global from "/usr/app/rdp-employee/middleware/lens-step.global.ts";
export const globalMiddleware = [
  validate,
  _00_45persist_45utm_45global,
  _01_45guest_45init_45global,
  _02_45refresh_45guest_45global,
  lens_45step_45global
]
export const namedMiddleware = {}