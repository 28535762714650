<template>
  <div
    v-if="product"
    class="product-list-item text-center md:max-w-[400px] lg:w-full"
    :class="fullWidth ? 'w-full' : ' w-[256px]'"
  >
    <NuxtLink
      :to="{
        path: `/products/${product.sku}${
          $route.query?.keyphrase ? `?keyphrase=${$route.query?.keyphrase}` : ''
        }`,
        query: { activeImage: activeImage },
      }"
    >
      <div class="flex h-[30px] items-center justify-start">
        <span
          v-if="isBestseller"
          class="rounded-full bg-[#EBF5F9] px-2 py-1 text-sm font-semibold leading-[21px] text-[#1B4B66]"
          >Bestseller</span
        >
      </div>
      <div
        class="mx-auto mb-2.5 flex h-[150px] max-h-36 w-full items-center lg:h-32"
        @click="clickedObjectIDsAfterSearch"
      >
        <img
          class="mx-auto max-h-full max-w-full px-2 sm:pt-4"
          :src="productImage"
          loading="lazy"
        />
      </div>
    </NuxtLink>

    <ZnButton
      v-if="showTryOnButton"
      class="border-grays-dark mx-auto px-4 py-2 text-sm font-semibold"
      type="secondary"
      @click="emit('tryOnClicked', product?.sku)"
    >
      <ZenniIconsIconCamera size="sm" class="mr-[6px]" />
      Try on
    </ZnButton>

    <div class="flex flex-col items-start justify-center gap-2 p-2">
      <p
        class="text-grays-darkest truncate whitespace-nowrap text-xl font-semibold leading-[30px]"
        style="max-width: 100%"
        title="product.name"
      >
        {{ product.name || '' }}
      </p>
      <span
        v-if="availableCredit <= (product?.price ?? 0)"
        class="text-xl leading-[30px]"
        data-testid="price"
      >
        {{ itemPrice || '$0.00' }}
      </span>
      <span v-else class="text-grays-dark leading-[24px]">
        <span class="text-purples-dark font-semibold">Free with credit</span>
        was {{ itemPrice || '$0.00' }}
      </span>
      <ZnColorDot
        selected
        :color="product.frame_color"
        :color-rgb="product.rgb_color_code"
      />
      <ZnRating v-if="productRating" :value="productRating" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getAlgoliaInsights,
  useGetAlgoliaSearchIndex,
} from '@/composables/useAlgolia'
import { useGtmTrackEvent } from '@/composables/useGtmTrackEvent'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { ref, computed, defineComponent, watch } from '#imports'
import { useVTO } from '~/composables/useVTO'

defineComponent({
  name: 'ProductListItem',
})

const props = defineProps({
  product: {
    type: Object || Array,
    default: Object,
    required: true,
  },
  index: {
    type: Number,
    default: 0,
  },
  showTryOnButton: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: true,
  },
  availableCredit: {
    type: Number,
    default: 0,
  },
  activeImage: {
    type: String,
    required: false,
    default: undefined,
  },
})

const emit = defineEmits(['tryOnClicked'])

const { vto, getFrontalFrame } = useVTO()
const { eventInsights } = getAlgoliaInsights()
const { plpClickItemTrackEvent } = useGtmTrackEvent()

const product = ref<any>(props.product)

const itemPrice = computed(() => {
  return formatCurrency(props.product?.price, 'en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
  })
})

const clickedObjectIDsAfterSearch = () => {
  plpClickItemTrackEvent(product.value, props.index)
  const algoliaIndex = useGetAlgoliaSearchIndex()
  if (eventInsights?.value) {
    eventInsights?.value?.('clickedObjectIDs', {
      index: algoliaIndex,
      eventName: 'PLP: Product Clicked',
      objectIDs: [product.value.sku.toString()],
    })
  }
}

watch(vto, (newVal) => {
  if (newVal && newVal?.status) {
    getFrontalFrame({
      glassesId: product.value.sku,
    })
  }
})

const productRating = computed(() => {
  // TODO: remove hardcoded value when we have a rating
  return undefined
})
const productImage = computed(() => {
  if (props.activeImage === 'tinted') {
    return (
      (product.value?.image_url_tinted_view ||
        product.value?.image_url_front_view) ??
      ''
    )
  }
  return product.value?.image_url_front_view ?? ''
})

const isBestseller = computed(() => {
  return product.value?.badges?.includes('bestselling') ?? false
})
</script>

<style scoped>
.overlay-container {
  width: 100%;
  height: 357px;
}
.pattern-bg {
  background: linear-gradient(
    219.81deg,
    #000 9.02%,
    #fff 24.91%,
    #000 38.87%,
    #fff 53.31%,
    #000 66.79%,
    #fff 80.26%,
    #000 92.78%
  );
}
.multicolor-bg {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFDSURBVHgBpZK/SsNAAId/d006JBGsfyYRETehWBAEwcU38H3curUuGZRsBd9CcdBBfIlSRFxq6x9IcllMiHeJsWfSS0z9IIS7XD6+3IVMT0/ipZ1P/AcnbENjTzoazRjGZohF6IYHGES7oGLgDpvwRjrq4kTtRCLQsklRRrjW2v7bZ9pRB3bY+Rlr8kP/Ma2qkgmBEMnQ/CIhy4TKkpykUCTLCI1hbv0+gF64n+zLPCgUeCN+AMNZmdhUlURZlMGedVD+a5xv7JVKSosy+h8u+u64all5UXd9jEHrDQhe0CAWDOMQtYuclddU8o3LruCxG9Qqslen/JoU5llwD8JfsczjapEQCJEKP7hL7nkZLZaoJbLMZ7fzi3prE74v1RJZRogO0ziaFV0uv9eSZIjN9/zrtOiMl1y0uCTGQrDgAZSa+ALaUnmJ74U1/AAAAABJRU5ErkJggg==);
}

.pink-bg {
  background: #aa0987;
}

.rainbow-bg {
  background-color: orange;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(red),
    color-stop(orange),
    color-stop(yellow),
    color-stop(green),
    color-stop(cyan),
    color-stop(blue),
    to(violet)
  );
  background-image: -o-linear-gradient(
    left,
    red,
    orange,
    #ff0,
    green,
    #0ff,
    #00f,
    violet
  );
  background-image: linear-gradient(
    to right,
    red,
    orange,
    #ff0,
    green,
    #0ff,
    #00f,
    violet
  );
}
</style>
