<template>
  <template v-if="!loading">
    <LandingSafety v-if="isSafetyCatalog" />
    <LandingVision v-else />
  </template>
  <template v-else>
    <ZnLoadingBar />
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGuestStore } from '~/store/guest'

const { isSafetyCatalog, loading } = storeToRefs(useGuestStore())
</script>
