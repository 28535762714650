import { defineStore } from 'pinia'
import { usePrescription, useZenniAlgoliaSearch } from '#imports'
import { useLensInfoApi } from '@/composables/api/useLensInfo'
import { useFrameInfoApi } from '@/composables/api/useFrameInfo'

export const useLensStore = defineStore('lensStore', () => {
  const { prescription } = usePrescription()
  const lensInfoApi = useLensInfoApi()
  const frameInfoApi = useFrameInfoApi()

  const getFrameFilters = (frameData: any) => {
    const filterParts: string[] = ['enabled:true']

    if (frameData?.Redemption_Catalog?.length > 0) {
      const catalogFilterParts = frameData?.Redemption_Catalog?.map(
        (catalog: string) => {
          return `Redemption_Catalog:${catalog}`
        },
      )
      filterParts.push(catalogFilterParts.join(' AND '))
    }

    if (frameData?.Safety_Certification?.length > 0) {
      const safetyFilterParts = frameData?.Safety_Certification?.map(
        (safety: string) => {
          return `Safety_Certification:${safety}`
        },
      )
      const safetyFilter = safetyFilterParts.join(' OR ')
      filterParts.push(`(${safetyFilter})`)
    }

    return filterParts.join(' AND ')
  }

  const getLensInfoFromAlgolia = async (
    prescriptionData = null,
    frameData: any,
    hitsPerPage: number = 100,
    customFilter: string = '',
  ) => {
    const baseFilters = 'family:lens'
    const frameFilters = getFrameFilters(frameData)
    return await lensInfoApi.search(
      prescriptionData || (prescription?.value as any),
      {
        query: '',
        requestOptions: {
          filters: [frameFilters, customFilter, baseFilters]
            .filter((i) => i)
            .join(' AND '),
          hitsPerPage,
          facets: ['*'],
        },
      },
    )
  }

  const getLensPrescriptionRange = async (
    lensIndex: any,
    prescriptionData: any,
  ) => {
    return await lensInfoApi.getLensPrescriptionRange(
      lensIndex,
      prescriptionData || prescription.value,
    )
  }

  const getFramePrescriptionRange = async (frameSku?: string) => {
    const { search } = useZenniAlgoliaSearch()
    let filters
    if (frameSku) {
      filters = `sku:${frameSku}`
    }
    const res = await search({
      query: '',
      requestOptions: {
        facets: ['*'],
        filters,
      },
    })
    const framePrescriptionRange = res.facets_stats
    return {
      PD: {
        MIN: framePrescriptionRange?.FrameMinPD?.min,
        MAX: framePrescriptionRange?.FrameMaxPD?.max,
      },
      SPH: {
        MIN: framePrescriptionRange?.FrameMinSPH.min,
        MAX: framePrescriptionRange?.FrameMaxSPH.max,
      },
      CYL: {
        MIN: framePrescriptionRange?.FrameMinCYL.min,
        MAX: framePrescriptionRange?.FrameMaxCYL.max,
      },
      JOINT: {
        MIN: framePrescriptionRange?.FrameJointMin.min,
        MAX: framePrescriptionRange?.FrameJointMax.max,
      },
    }
  }

  const getValue = (a: any, b: any, type: string = 'min') => {
    const isNoneValue = (val: any) =>
      val === undefined || val === null || val === '' || isNaN(val)
    if (isNoneValue(a) && isNoneValue(b)) {
      return 0
    } else if (!isNoneValue(a) && isNoneValue(b)) {
      return a
    } else if (isNoneValue(a) && !isNoneValue(b)) {
      return b
    } else {
      return type === 'min'
        ? Math.min(Number(a), Number(b))
        : Math.max(Number(a), Number(b))
    }
  }

  const getPrescriptionRange = async (
    lensIndex: any = '',
    prescriptionData: any = prescription.value,
    framePrescriptionRange: any = null,
  ) => {
    const lensPrescriptionRange = await getLensPrescriptionRange(
      lensIndex,
      prescriptionData,
    )
    if (!framePrescriptionRange) {
      framePrescriptionRange = await getFramePrescriptionRange()
    }
    return {
      PD: {
        MIN: framePrescriptionRange?.PD?.MIN,
        MAX: framePrescriptionRange?.PD?.MAX,
      },
      SPH: {
        MIN: -getValue(
          lensPrescriptionRange?.LensNegativeMaxSPH.max,
          Math.abs(Number(framePrescriptionRange?.SPH.MIN)),
          'min',
        ),
        MAX: getValue(
          lensPrescriptionRange?.LensPositiveMaxSPH.max,
          framePrescriptionRange?.SPH.MAX,
          'min',
        ),
      },
      CYL: {
        MIN: -getValue(
          lensPrescriptionRange?.LensMaxCYL.max,
          Math.abs(Number(framePrescriptionRange?.CYL.MIN)),
          'min',
        ),
        MAX: getValue(
          lensPrescriptionRange?.LensMaxCYL.max,
          framePrescriptionRange?.CYL.MAX,
          'min',
        ),
      },
      JOINT: {
        MIN: -getValue(
          Math.abs(Number(lensPrescriptionRange?.LensMinSumCYL.min)),
          Math.abs(Number(framePrescriptionRange?.JOINT.MIN)),
          'min',
        ),
        MAX: getValue(
          lensPrescriptionRange?.LensMaxSumCYL.max,
          framePrescriptionRange?.JOINT.MAX,
          'min',
        ),
      },
      ADD: {
        MIN: 1,
        MAX: lensPrescriptionRange?.LensMaxNVAdd?.max || 3,
      },
    }
  }

  const isFramePrescriptionTypeValid = async (
    frameSku: string,
    prescriptionData: any = prescription.value,
  ) => {
    const res = await frameInfoApi.verifyFramePrescriptionType(
      frameSku,
      prescriptionData,
    )
    return res.hits.length > 0
  }

  return {
    getLensInfoFromAlgolia,
    getPrescriptionRange,
    getFramePrescriptionRange,
    isFramePrescriptionTypeValid,
  }
})
