import { defineNuxtRouteMiddleware } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to, from) => {
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ]
  const utm = utmKeys.reduce((acc: Record<string, string>, key) => {
    if (from.query[key]) {
      if (typeof from.query[key] === 'string') {
        acc[key] = from.query[key] as string
      }
    }
    return acc
  }, {})
  if (Object.keys(utm).length > 0) {
    to.query = {
      ...to.query,
      ...utm,
    }
  }
})
