<template>
  <div
    class="bg-teal-lightest text-grays-dark flex space-x-6 overflow-auto whitespace-nowrap p-6 lg:justify-center lg:space-x-16"
  >
    <div class="flex items-center space-x-2">
      <ZenniIconsIconGlasses size="base" class="text-grays-medium" />
      <span>Redeem glasses in 5 minutes!</span>
    </div>
    <div class="flex items-center space-x-2">
      <ZenniIconsIconTruck size="base" class="text-grays-medium" />
      <span>Delivery in 7-10 business days</span>
    </div>
    <div class="flex items-center space-x-2">
      <ZenniIconsIconUserHeadset size="base" class="text-grays-medium" />
      <span>24/7 customer service</span>
    </div>
    <div class="flex items-center space-x-2">
      <ZenniIconsIconBoxOpen size="base" class="text-grays-medium" />
      <span>30-day returns</span>
    </div>
  </div>
</template>
