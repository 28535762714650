<template>
  <section v-if="computedHits.length">
    <ProductListSection
      :title="`Most Popular for ${gift?.storeName}`"
      class="mx-auto max-w-7xl"
      :try-on-available-frames="tryOnAvailableFrames"
      :products="computedHits"
    />
  </section>
</template>

<script setup lang="ts">
import { normalizationFrameHit } from '@/utils/normalize/algoliaSearchNormalize'
import { ref, computed, watchEffect } from '#imports'
import { useGuestStore } from '~/store/guest'
import { useFittingbox } from '~/composables/useFittingbox'
import { formatSkuId } from '~/utils/skuIdGetters'
import { useEmployeeAlgolia } from '~/composables/useEmployeeAlgolia'
import type { ZnAlgoliaProduct } from '../Zenni/Plp/algolia-types'

const { search, result } = useEmployeeAlgolia<ZnAlgoliaProduct>()
const { gift } = useGuestStore()
const { getAvailableFrames } = useFittingbox()

// TODO: We should reconfigure the filters when we have more data about how to filter the popular products
const maxHits = 4
const popularProductsSearchParams = {
  query: '',
  requestOptions: {
    filters: `family:"frame"`,
    hitsPerPage: maxHits,
  },
}

// SSR
await search(popularProductsSearchParams)

const tryOnAvailableFrames = ref<string[]>([])

const computedHits = computed<any[]>(() => {
  if (!result.value) return []

  return result.value?.hits
    .filter((hit) => hit.enabled)
    .map((hit) => normalizationFrameHit(hit as any))
})

const refreshTryOnList = async () => {
  const availableFrames = await getAvailableFrames(
    computedHits.value.map((hit) => {
      return formatSkuId(hit.sku)
    }),
  )
  tryOnAvailableFrames.value = availableFrames
}

watchEffect(async () => {
  if (computedHits.value.length && process.client) {
    await refreshTryOnList()
  }
})
</script>
