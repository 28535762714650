<template>
  <component
    :is="tag || 'li'"
    :class="[
      'focus-visible:outline-offset inline-flex w-full cursor-pointer items-center gap-2 hover:bg-neutral-100 focus-visible:relative focus-visible:z-10 focus-visible:outline active:bg-neutral-200',
      sizeClasses[size],
      {
        'text-disabled-900 pointer-events-none cursor-not-allowed': disabled,
        'font-medium': selected,
      },
    ]"
    :disabled="disabled"
    data-testid="list-item"
  >
    <component
      :is="childrenTag"
      v-if="$slots.prefix"
      :class="disabled ? 'text-disabled-500' : 'text-neutral-500'"
    >
      <slot name="prefix" />
    </component>
    <component :is="childrenTag" class="flex w-full min-w-0 flex-col">
      <slot />
    </component>
    <component
      :is="childrenTag"
      v-if="$slots.suffix"
      :class="disabled ? 'text-disabled-500' : 'text-neutral-500'"
    >
      <slot name="suffix" />
    </component>
  </component>
</template>

<script setup lang="ts">
import { type PropType, type ConcreteComponent } from 'vue'

defineProps({
  size: {
    type: String as PropType<'base' | 'lg' | 'sm'>,
    default: 'base',
  },
  disabled: {
    type: Boolean,
    default: undefined,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: [String, Object] as PropType<string | ConcreteComponent>,
    default: undefined,
  },
  childrenTag: {
    type: String,
    default: 'span',
  },
})

const sizeClasses = {
  sm: 'text-sm px-4 py-1',
  base: 'px-4 py-2',
  lg: 'p-4',
}
</script>
