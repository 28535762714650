<template>
  <div class="flex items-center gap-2">
    <span
      role="img"
      :ariaLabel="label"
      :title="label"
      class="inline-flex items-center gap-[3px]"
      data-testid="rating"
    >
      <ZnIcon
        v-for="index in filled"
        :key="index"
        path="/img/icons/filled-star.svg"
        aria-hidden="true"
      />
      <ZnIcon
        v-for="index in empty"
        :key="index"
        path="/img/icons/empty-star.svg"
        aria-hidden="true"
      />
    </span>
    <span class="text-grays-darkest text-sm leading-[21px]">({{ value }})</span>
  </div>
</template>
<script setup lang="ts">
import { computed, toRefs } from 'vue'

const props = defineProps({
  max: {
    type: Number,
    default: 5,
  },
  value: {
    type: Number,
    default: 0,
  },
  ariaLabel: {
    type: String,
    default: undefined,
  },
})
const { value, max, ariaLabel } = toRefs(props)

const ratingValue = computed(() => Math.floor(value.value))

const filled = computed(() => ratingValue.value)
const empty = computed(() => max.value - filled.value)
const label = computed(
  () => ariaLabel?.value ?? `${value.value} out of ${max.value}`,
)
</script>
