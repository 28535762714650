<template>
  <ZenniZnFilterFilterCategory
    v-if="facet"
    :title="title"
    :count="selectedProxy?.length"
  >
    <ul class="flex flex-col">
      <ZenniZnListItem
        v-for="{ value, productCount, label } in (facetToShow as any)?.values"
        :key="value"
        :aria-label="label"
        size="lg"
        @click="toggleSelected(value)"
      >
        <div class="flex w-full items-center gap-2">
          <span
            :class="{
              'rounded-full ring-1 ring-neutral-300 ring-offset-[3px]':
                !selectedProxy?.includes(value),
            }"
          >
            <ZnColorDot
              size="sm"
              :color="value"
              :selected="selectedProxy?.includes(value)"
              without-border
            />
          </span>
          <span class="pl-1">{{ label }}</span>
          <ZenniZnCounter v-if="productCount">{{
            productCount
          }}</ZenniZnCounter>
        </div>
      </ZenniZnListItem>
      <li
        v-if="!showAll && (facet as any).values.length > itemsToShow"
        class="mx-auto cursor-pointer font-semibold"
      >
        <a class="text-teal-500" @click="showAll = true"> Show more + </a>
      </li>
    </ul>
  </ZenniZnFilterFilterCategory>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import type { ColorDotItemProps, ColorDotItemEmits } from '~/types/filter'

const props = defineProps<ColorDotItemProps>()
const emit = defineEmits<ColorDotItemEmits>()

const itemsToShow = 8

const showAll = ref(false)
const facetToShow = computed(() => {
  if (showAll.value) {
    return props.facet
  }
  return {
    ...props.facet,
    values: (props.facet as any)?.values.slice(0, itemsToShow),
  }
})

const toggleSelected = (value: ColorDotItemProps['facet']['value']) => {
  const index = selectedProxy.value?.indexOf(value) ?? -1
  if (index === -1) {
    selectedProxy.value?.push(value)
  } else {
    selectedProxy.value?.splice(index, 1)
  }
  emit('update:selected', selectedProxy.value!)
}

const selectedProxy = computed(() => props.selected)
</script>
