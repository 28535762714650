/**
 *
 * @param filters - filters object Record<string, string[]>
 * @returns string with filters in Algolia format
 *
 *  for facet filters, the value string should match ^[a-zA-Z0-9_-]+$ regex
 *  for example, Rectangle, Aviator, etc.
 *  if there are more than one value in the array, the filter will be applied as OR
 *
 *  for number range filters, the value string should match ^\d+-\d*$ regex
 *  for example, 0-50, 50-100, 100-150, etc.
 *  for more than 150, use 150- (150 and above) (without digits after the dash)
 */
export const mapFilters = (
  filters: Record<string, string[]>,
): string | undefined => {
  const facetFilters = Object.entries(filters)
    .filter(([, values]) => values?.length > 0)
    .map(([key, values]) => {
      const mappedValues = values.map((val) => {
        if (/^\[.*\]$/.test(val)) {
          const values = val.replace('[', '').replace(']', '').trim()
          const valuesList = values.split(',').map((value) => `${key}:${value}`)
          return `(${valuesList.join(' OR ')})`
        }
        if (/^\d+-\d*$/.test(val)) {
          // number range filter
          const [min, max] = val.split('-')
          if (max) {
            return `${key}:${min} TO ${max}`
          }
          return `${key} > ${min}`
        }
        if (/^true|false$/.test(val)) {
          // boolean filter
          return `${key}:${val}`
        }

        // string filter
        return `${key}:"${val}"`
      })
      return mappedValues.length > 1
        ? `(${mappedValues.join(' OR ')})`
        : mappedValues[0]
    })
    .filter(Boolean)

  return facetFilters.length > 0 ? facetFilters.join(' AND ') : undefined
}
