<template>
  <div class="max-w-[270px]" role="list">
    <ZnSlider
      prev-disabled
      next-disabled
      :items="colors"
      class="px-1"
      container-classes="p-1 gap-3"
    >
      <template #item="slotProps">
        <ZnColorDot
          :color="slotProps.data as any"
          :selected="valueProxy === slotProps.data"
          :data-testid="`color-dot-${slotProps.data}`"
          @click="updateValue($event, slotProps.data as any)"
        />
      </template>
    </ZnSlider>
  </div>
</template>
<script setup lang="ts">
import { computed, toRefs } from 'vue'
import type { ZnColorDotSelectorEmits, ZnColorDotSelectorProps } from './types'

const emit = defineEmits<ZnColorDotSelectorEmits>()

const props = withDefaults(defineProps<ZnColorDotSelectorProps>(), {
  colors: () => [],
})

const { modelValue } = toRefs(props)

const valueProxy = computed({
  get: () => modelValue.value,
  set: (value: ZnColorDotSelectorProps['modelValue']) =>
    emit('update:modelValue', value as any),
})

const updateValue = (
  ev: Event,
  color: ZnColorDotSelectorProps['modelValue'],
) => {
  ev.preventDefault()
  ev.stopPropagation()
  valueProxy.value = color
}
</script>
