import { sumBy } from 'lodash-es'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useNuxtApp } from '#imports'
import type { ZnProductCardProps } from '~/components/Zenni/ZnProductCard/types'
import type { ZnAlgoliaProduct } from '~/components/Zenni/Plp/algolia-types'
import { useGuestStore } from '~/store/guest'

const priceRanges = [
  {
    label: '$0-$30',
    value: '0-30',
    min: 0,
    max: 30,
  },
  {
    label: '$30-$50',
    value: '30-50',
    min: 30,
    max: 50,
  },
  {
    label: '$50-$70',
    value: '50-70',
    min: 50,
    max: 70,
  },
  {
    label: '$70+',
    value: '70-',
    min: 70,
    max: 1000,
  },
]

export const useFilters = () => {
  const {
    $brandConfig,
    $shapeConfig,
    $genderConfig,
    $colorConfig,
    $materialConfig,
    $rimConfig,
    $featureConfig,
  } = useNuxtApp()

  const mapFilters = {
    Frame___Gender_search: {
      title: 'Gender',
      labelFn: (val: string) => {
        const gender = $genderConfig.find((gender: any) => gender.code === val)
        return (
          gender?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
      ascLabelOrder: true,
      filterOrder: 1,
    },
    Frame___Prescription_types_search: {
      title: 'Prescription Type',
      labelFn: (val: string) => {
        return val
      },
      ascLabelOrder: true,
      filterOrder: 2,
    },
    Main_Color_search: {
      title: 'Color',
      valuesFn: (val: string) => val.toLowerCase(),
      labelFn: (val: string) => {
        const color = $colorConfig.find((color: any) => color.code === val)
        return (
          color?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
      ascLabelOrder: true,
      filterOrder: 7,
    },
    Frame___Shape_search: {
      title: 'Shape',
      ascLabelOrder: true,
      labelFn: (val: string) => {
        const shape = $shapeConfig.find((shape: any) => shape.code === val)
        return (
          shape?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
      imageFn: (val: string) => {
        const shape = $shapeConfig.find((shape: any) => shape.code === val)
        return shape?.values?.imageurl?.data || ''
      },
      filterOrder: 6,
    },
    Frame___Rim_type_search: {
      title: 'Rim',
      ascLabelOrder: true,
      labelFn: (val: string) => {
        const rim = $rimConfig.find((rim: any) => rim.code === val)
        return (
          rim?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
      imageFn: (val: string) => {
        const rim = $rimConfig.find((rim: any) => rim.code === val)
        return rim?.values?.imageurl?.data || ''
      },
      filterOrder: 6,
    },
    Brand_search: {
      title: 'Brand',
      ascLabelOrder: true,
      labelFn: (val: string) => {
        const brand = $brandConfig.find((brand: any) => brand.code === val)
        return (
          brand?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
      filterOrder: 6,
    },
    Frame___Size_search: {
      title: 'Size',
      labelFn: (val: string) => {
        switch (val) {
          case 'Extra Small':
            return 'XS'
          case 'extrasmall':
            return 'XS'
          case 'small':
            return 'S'
          case 'medium':
            return 'M'
          case 'large':
            return 'L'
          case 'extralarge':
            return 'XL'
          default:
            return val
        }
      },
      labelOrder: [
        'Extra Small',
        'extrasmall',
        'small',
        'medium',
        'large',
        'extralarge',
      ],
      filterOrder: 5,
    },
    Frame___Material_search: {
      title: 'Material',
      ascLabelOrder: true,
      labelFn: (val: string) => {
        const material = $materialConfig.find(
          (material: any) => material.code === val,
        )
        return (
          material?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
      filterOrder: 7,
    },
    Price_search: {
      title: 'Price',
      filterOrder: 3,
      isPrice: true,
    },
    Frame___Features_search: {
      title: 'Features',
      filterOrder: 4,
      labelFn: (val: string) => {
        const feature = $featureConfig.find(
          (feature: any) => feature.code === val,
        )
        return (
          feature?.values?.label?.data ||
          (val[0].toUpperCase() + val.slice(1)).replace('_', '')
        )
      },
    },
    Is_Under_25_Limit: {
      title: 'Under $25',
      filterOrder: 8,
      labelFn: () => 'Under $25',
    },
    Is_Under_50_Limit: {
      title: 'Under $50',
      filterOrder: 8,
      labelFn: () => 'Under $50',
    },
    Is_Under_75_Limit: {
      title: 'Under $75',
      filterOrder: 8,
      labelFn: () => 'Under $75',
    },
    Is_Under_100_Limit: {
      title: 'Under $100',
      filterOrder: 8,
      labelFn: () => 'Under $100',
    },
    Is_Under_125_Limit: {
      title: 'Under $125',
      filterOrder: 8,
      labelFn: () => 'Under $125',
    },
    Is_Under_150_Limit: {
      title: 'Under $150',
      filterOrder: 8,
      labelFn: () => 'Under $150',
    },
    Is_Under_175_Limit: {
      title: 'Under $175',
      filterOrder: 8,
      labelFn: () => 'Under $175',
    },
    Is_Under_200_Limit: {
      title: 'Under $200',
      filterOrder: 8,
      labelFn: () => 'Under $200',
    },
  }

  const processMapFilters = (initialFilters: any) => {
    const guestStore = useGuestStore()
    const { currentGift } = storeToRefs(guestStore)

    const creditLeft = currentGift.value
      ? guestStore.calculateGiftAmt(currentGift.value)
      : 0

    if (creditLeft >= 30) {
      priceRanges[0].label = 'Free with Credit'
    }

    const filters = { ...initialFilters }
    for (const key of Object.keys(filters)) {
      const mapFilterItem = (mapFilters as any)[key]
      const filterItem = filters[key]
      if (mapFilterItem && filterItem) {
        filterItem.title = mapFilterItem.title
        filterItem.filterOrder = mapFilterItem.filterOrder

        if (mapFilterItem.isPrice) {
          filterItem.title = mapFilterItem.title
          filterItem.facet!.values = priceRanges
            .map((range) => {
              return {
                label: range.label,
                value: range.value,
                productCount: sumBy(
                  filterItem?.facet?.values.filter(
                    (item: any) =>
                      parseFloat(item.value) >= range.min &&
                      parseFloat(item.value) < range.max,
                  ),
                  'productCount',
                ),
              }
            })
            .filter((item) => item.productCount > 0) // only keeps ranges with products
        } else {
          if (mapFilterItem.ascLabelOrder && filterItem?.facet?.values) {
            filterItem.facet!.values = filterItem!.facet!.values.sort(
              (a: any, b: any) => {
                return a.label.localeCompare(b.label)
              },
            )
          }

          if (mapFilterItem.labelOrder && filterItem?.facet?.values) {
            filterItem.facet!.values = filterItem!.facet!.values.sort(
              (a: any, b: any) => {
                return (
                  mapFilterItem.labelOrder!.indexOf(a.label) -
                  mapFilterItem.labelOrder!.indexOf(b.label)
                )
              },
            )
          }

          if (mapFilterItem.labelFn && filterItem?.facet?.values) {
            filterItem.facet!.values = filterItem!.facet!.values.map(
              (val: any) => ({
                ...val,
                label: mapFilterItem.labelFn!(val.label),
              }),
            )
          }
          if (mapFilterItem.imageFn && filterItem?.facet?.values) {
            filterItem.facet!.values = filterItem!.facet!.values.map(
              (val: any) => ({
                ...val,
                imageurl: mapFilterItem.imageFn!(val.value),
              }),
            )
          }
        }

        // restore original product count to always show all values
        // if we want to have productCount updated after each field is applied
        // just comment this line
      }
    }
    return filters
  }

  const groupedFiltersFacetsName = ref([
    'Frame___Shape_search',
    'Frame___Rim_type_search',
  ])

  const freeGlassesFacetsName = ref([
    'Is_Under_25_Limit',
    'Is_Under_50_Limit',
    'Is_Under_75_Limit',
    'Is_Under_100_Limit',
    'Is_Under_125_Limit',
    'Is_Under_150_Limit',
    'Is_Under_175_Limit',
    'Is_Under_200_Limit',
  ])

  const frameImagesFields = [
    'Product_Eyeglass_Front_image_url',
    'Product_Eyeglass_Angle_image_URL',
    'Product_Eyeglass_Side_image_URL',
    'Product_Sunglass_Front_image_URL',
    'Product_Sunglass_Angle_image_URL',
    'Product_Sunglass_Side_image_URL',
    'Product_Clip_on_Front_image_URL',
    'Product_Clip_on_Angle_image_URL',
    'Product_Clip_on_Side_image_URL',
  ]

  const frameSunglassesImagesFields = [
    'Product_Sunglass_Front_image_URL',
    'Product_Sunglass_Angle_image_URL',
    'Product_Sunglass_Side_image_URL',
  ]
  const getFirstImage = (product: any, isSunglasses = false) => {
    const imgFields = isSunglasses
      ? (frameSunglassesImagesFields as (keyof ZnAlgoliaProduct)[])
      : (frameImagesFields as (keyof ZnAlgoliaProduct)[])
    const imgField = imgFields.find((field) => product[field])
    return imgField ? product[imgField] : undefined
  }

  const mapHitToProductCard = (
    hit: any,
    isSunglasses = false,
  ): ZnProductCardProps => {
    const variants = Object.values(hit.products)
      .filter((product: any) => product.enabled)
      .map((product: any) => {
        return {
          sku: product.sku,
          name: product.Name,
          price: product.Price,
          badges: product.Badges,
          image: getFirstImage(product, isSunglasses),
          hoverImage:
            product.Product_Eyeglass_Angle_image_URL ||
            product.Product_Eyeglass_Side_image_URL ||
            product.Product_Sunglass_Angle_image_URL ||
            product.Product_Sunglass_Side_image_URL ||
            product.Product_Clip_on_Angle_image_URL ||
            product.Product_Clip_on_Side_image_URL ||
            '',
          color: product.Main_Color,
        }
      })
    return {
      id: hit.objectID,
      variants,
      isBestSelling: hit.Badges_search?.includes('bestselling') ?? false,
    } as ZnProductCardProps
  }

  return {
    mapFilters,
    processMapFilters,
    groupedFiltersFacetsName,
    mapHitToProductCard,
    freeGlassesFacetsName,
  }
}
