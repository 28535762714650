import type { FieldToLabelMap } from './types'

export const emptyAddress = {
  country: { code: 'US' as const, name: 'United States' }, // default country
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  province: { code: '', name: '' },
  zip: '',
  phone: '',
}

export const fieldToLabelMap: FieldToLabelMap = {
  country: 'country',
  firstName: 'firstName',
  lastName: 'lastName',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  province: 'zone',
  zip: 'postalCode',
  phone: 'phone',
}
