<template>
  <div class="relative flex min-w-0 items-center">
    <div
      ref="containerRef"
      class="flex snap-x snap-mandatory flex-wrap items-center gap-2 overflow-x-auto motion-safe:scroll-smooth"
      :class="[containerClasses, sliderClasses]"
    >
      <slot name="prefix"></slot>
      <div v-for="(item, index) of items" :key="index">
        <slot name="item" :data="item" :index="index"></slot>
      </div>
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
const props = defineProps({
  items: {
    type: Array,
    required: false,
    default: () => [],
  },
  containerClasses: {
    type: String,
    required: false,
    default: '',
  },
  withScrollbar: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const { items } = toRefs(props)
const sliderClasses = !props.withScrollbar
  ? '[-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden'
  : ''
</script>
